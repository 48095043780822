// src/components/ResponsiveDrawer.js
import React, { useContext, useState } from "react";
import { Drawer } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Pathname, useLocation, useNavigate } from "react-router";
import { BannerTextContext } from "../../Context/BannerTextContext";
import { BannerTextConstant } from "../../Constant";
import MenuItemWithPopover from "./MenuItemWithPopover";
import { UHLogo, UHLogoBackground } from "../../Assets";
import { Typography } from "@mui/material";

interface MainContentProps {
  open: boolean;
}
interface NavItemProps {
  active?: boolean;
}
const drawerWidth = "100%";

const MainContent = styled("main")<MainContentProps>(({ theme, open }) => ({
  flexGrow: 1,
  //padding: theme.spacing(3),
  marginLeft: `-${drawerWidth}px`,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const NavBarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  //alignItems: "center",
  justifyContent: "space-between",
}));
const ItemsContainer = styled("div")(({ theme }) => ({
  padding: "9%",
}));
const LogoContainer = styled("div")(({ theme }) => ({
  zIndex: 10,
  width: "90%",
  position: "absolute",
  top: 0,
  left: 0,
}));
const LogoBGContainer = styled("div")(({ theme }) => ({
  zIndex: 9,
  width: "60%",
  position: "relative",
  top: 0,
  left: 0,
}));
const MobileMenuDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    backgroundColor: theme.palette.greyColor.light,
    padding: "0px",
  },
}));

const NavItem = styled("li")(({ active }: NavItemProps) => ({
  cursor: "pointer",
  //color: theme.palette.whiteColor.main,
  listStyleType: "none",
  padding: "2vh",
  background: active ? "linear-gradient(90deg,#004AAD,#fff)" : null,
  webkitBackgroundClip: active ? "text" : null,
  webkitTextFillColor: active ? "transparent" : null,
  backgroundClip: active ? "text" : null,
  color: active ? "transparent" : "#ffffff",
}));

const NavBarItemsMobile = ({ drawerOpen, handleDrawerOpen }) => {
  const navigateTo = useNavigate();
  const handleClickNavItem = (url: Pathname) => {
    navigateTo(url);
  };

  const { ChangeBannerContent } = useContext(BannerTextContext);
  const { pathname } = useLocation();
  const [activeIndex, setActiveIndex] = useState(pathname);
  const DrawerItems = () => {
    return (
      <MainContent open={drawerOpen}>
        <NavBarContainer>
          <LogoBGContainer>
            <img src={UHLogoBackground} alt="UHLogo Background" />
            <LogoContainer>
              <img src={UHLogo} alt="Unitedhands Logo" />
            </LogoContainer>
          </LogoBGContainer>
        </NavBarContainer>
        <ItemsContainer>
          <Typography variant="mobileMenuFont">
            <NavItem
              active={activeIndex === "/home"}
              onClick={() => {
                handleClickNavItem("/home");
                setActiveIndex("/home");
                handleDrawerOpen(!drawerOpen);
                ChangeBannerContent(
                  BannerTextConstant.filter(
                    (item) => item.matchingRoute === "/home"
                  )[0]
                );
              }}>
              Home
            </NavItem>
            <NavItem active={activeIndex === "/services"}>
              <MenuItemWithPopover
                textToShow="Services/Products"
                handleItemClick={() => setActiveIndex("/services")}
                closeMobileDrawer={() => handleDrawerOpen(false)}
              />
            </NavItem>
            <NavItem
              active={activeIndex === "/clients"}
              onClick={() => {
                handleClickNavItem("/clients");
                setActiveIndex("/clients");
                handleDrawerOpen(!drawerOpen);
                ChangeBannerContent(
                  BannerTextConstant.filter(
                    (item) => item.matchingRoute === "/clients"
                  )[0]
                );
              }}>
              Clients/Parterships
            </NavItem>
            <NavItem
              active={activeIndex === "/accomplished"}
              onClick={() => {
                handleClickNavItem("/accomplished");
                setActiveIndex("/accomplished");
                handleDrawerOpen(!drawerOpen);
                ChangeBannerContent(
                  BannerTextConstant.filter(
                    (item) => item.matchingRoute === "/accomplished"
                  )[0]
                );
              }}>
              Accomplished Projects
            </NavItem>
            <NavItem
              active={activeIndex === "/about-us"}
              onClick={() => {
                handleClickNavItem("/about-us");
                setActiveIndex("/about-us");
                handleDrawerOpen(!drawerOpen);
                ChangeBannerContent(
                  BannerTextConstant.filter(
                    (item) => item.matchingRoute === "/about-us"
                  )[0]
                );
              }}>
              About Us
            </NavItem>
            <NavItem
              active={activeIndex === "/contact-us"}
              onClick={() => {
                handleClickNavItem("/contact-us");
                setActiveIndex("/contact-us");
                handleDrawerOpen(!drawerOpen);
                ChangeBannerContent(
                  BannerTextConstant.filter(
                    (item) => item.matchingRoute === "/contact-us"
                  )[0]
                );
              }}>
              Contact Us
            </NavItem>
          </Typography>
        </ItemsContainer>
      </MainContent>
    );
  };

  return (
    <MobileMenuDrawer anchor="right" open={drawerOpen}>
      <DrawerItems />
    </MobileMenuDrawer>
  );
};

export default NavBarItemsMobile;
