import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import React from "react";
import { ImageWithText } from "../../Components";
import {
  OtherServicesData,
  OtherServicesHeadingImageDescriptionCardData,
  OthersPageWelcomeMessage,
} from "../../Constant";
import FlipCards from "../../Components/FlipCards";
import ImageHeaderBlueDescriptionCard from "../../Components/ImageHeaderBlueDescriptionCard";
import FullWidthBox from "../../Components/FullWidthBox";

const CardContainer = styled(Box)(({ theme }) => ({
  perspective: "100%",
  //overflow: "hidden",
}));
const OuterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "40px",
  paddingBottom: "50px",
}));

const BenefitsContainer = styled(Box)(({ theme }) => ({
  padding: "30px 40px",
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  paddingBottom: "50px",
}));
const BenefitsCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: 4,
}));
const OtherServicesPage = () => {
  const theme = useTheme();
  return (
    <Grid container direction={"column"}>
      <ImageWithText
        isTextAfterImage={true}
        isButtonNeeded={false}
        description={OthersPageWelcomeMessage.description}
        imageToShow={OthersPageWelcomeMessage.imageToShow}
      />
      <OuterBox>
        {OtherServicesHeadingImageDescriptionCardData.map((item, index) => {
          return (
            <CardContainer>
              <Grid>
                <ImageHeaderBlueDescriptionCard
                  dataToShow={
                    OtherServicesHeadingImageDescriptionCardData[index]
                  }
                />
              </Grid>
            </CardContainer>
          );
        })}
      </OuterBox>
      <FullWidthBox backgroundColorValue={theme.palette.backgroundBlue.dark}>
        <BenefitsContainer>
          <Typography variant="headingFont" sx={{ textAlign: "center" }}>
            Benefits that you will gain from us
          </Typography>
          <Typography
            variant="bodyContentFont"
            sx={{
              textAlign: "center",
              padding: "0px 15%",
              [theme.breakpoints.down("md")]: { padding: "0px 2%" },
            }}
          >
            Data analysis is a powerful tool that empowers organizations to make
            evidence-based decisions, enhance efficiency, and gain a competitive
            edge in the ever-evolving business landscape.
          </Typography>
          <BenefitsCardContainer>
            <div> 1 2 3 4 5</div>

            <div>6789</div>
          </BenefitsCardContainer>
        </BenefitsContainer>
      </FullWidthBox>
      <FlipCards
        heading={"Industries We Serve"}
        flipCardData={OtherServicesData}
      />
    </Grid>
  );
};

export default OtherServicesPage;
