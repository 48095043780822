import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { ImageComp, ImageWithText } from "../../Components";
import { IosPageWelcomeMessage, IOSProcessContent } from "../../Constant";
import {
  tech1,
  tech2,
  tech3,
  tech4,
  tech5,
  tech6,
  tech7,
  tech8,
  tech9,
  tech10,
  tech11,
} from "../../Assets";
import IOSProcess from "./IOSProcess";
import FullWidthBox from "../../Components/FullWidthBox";

const IOSPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container direction={"column"}>
      <ImageWithText
        isTextAfterImage={true}
        isButtonNeeded={false}
        mainHeader={IosPageWelcomeMessage.heading1}
        mainHeaderLine2={IosPageWelcomeMessage.heading2}
        description={IosPageWelcomeMessage.description}
        description2={IosPageWelcomeMessage.description2}
        description3={IosPageWelcomeMessage.description3}
        imageToShow={IosPageWelcomeMessage.imageToShow}
      />
      <FullWidthBox>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            //justifyContent: "center",
          }}>
          <div
            style={{
              backgroundColor: theme.palette.primary.dark,
              width: isMobile ? "100%" : "58%",
            }}>
            <Typography
              variant="headingFont"
              sx={{
                color: theme.palette.whiteColor.main,
                marginLeft: isMobile ? "5px" : "70px",
                padding: "1%",
              }}>
              Process Taken in iOS Application Development
            </Typography>
          </div>

          <Grid
            sx={{
              display: "flex",
              paddingBottom: "50px",
            }}>
            <div
              style={{
                width: isMobile ? "0px" : "7px",
                backgroundColor: theme.palette.primary.dark,
              }}></div>
            <Grid
              sx={{
                width: isMobile ? "0px" : "15%",
                backgroundColor: theme.palette.backgroundBlue.dark,
              }}></Grid>

            <Grid sx={{ padding: isMobile ? "0px 0px" : "0px 120px" }}>
              <IOSProcess items={IOSProcessContent} />
            </Grid>
          </Grid>
        </Grid>
      </FullWidthBox>
      <FullWidthBox
        backgroundColorValue="rgba(123, 152, 255, 0.32)"
        SxProps={{ marginBottom: "calc(-3%)" }}>
        <Grid
          sx={{ backgroundColor: "rgba(123, 152, 255, 0.32)", padding: "2%" }}>
          <Typography
            paragraph
            variant="bodyContentFont"
            sx={{ textAlign: "center" }}>
            <span style={{ color: "blue", textDecoration: "underline" }}>
              Core Technologies
            </span>{" "}
            used in iOS Application Development
          </Typography>
          <ImageComp
            ImageList={[
              tech1,
              tech2,
              tech3,
              tech4,
              tech5,
              tech6,
              tech7,
              tech8,
              tech9,
              tech10,
              tech11,
            ]}></ImageComp>
        </Grid>
      </FullWidthBox>
    </Grid>
  );
};

export default IOSPage;
