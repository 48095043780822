import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";
import BaseLayout from "./Layout/BaseLayout/index";
import HomePage from "./Content/HomePage/index";
import React from "react";
import IOSPage from "./Content/Services/IOSPage";
import Clients from "./Content/Clients";
import Accomplished from "./Content/Accomplished";
import ContactUs from "./Content/ContactUs";
import AboutUs from "./Content/AboutUs";
import AndroidPage from "./Content/Services/AndroidPage";
import MaintenanceAndSupportPage from "./Content/Services/MaintenanceAndSupportPage";
import CoreTechnologiesPage from "./Content/Services/CoreTechnologiesPage";
import CloudApplicationPage from "./Content/Services/CloudApplicationPage";
import OtherServicesPage from "./Content/Services/OtherServicesPage";
import WebDevelopmentPage from "./Content/Services/WebDevelopmentPage";
import ItStaffingSolutions from "./Content/Services/ItStaffingSolutions";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="home" replace />,
      },
      {
        path: "",
        children: [
          {
            path: "",
            element: <Navigate to="home" replace />,
          },
          {
            path: "home",
            element: <HomePage />,
          },
          {
            path: "ios",
            element: <IOSPage />,
          },
          {
            path: "android",
            element: <AndroidPage />,
          },
          {
            path: "it-staffing-solutions",
            element: <ItStaffingSolutions />,
          },
          {
            path: "maintenance-support",
            element: <MaintenanceAndSupportPage />,
          },
          {
            path: "core-technologies",
            element: <CoreTechnologiesPage />,
          },
          {
            path: "cloud-application",
            element: <CloudApplicationPage />,
          },
          {
            path: "other-services",
            element: <OtherServicesPage />,
          },
          {
            path: "web-development",
            element: <WebDevelopmentPage />,
          },
          {
            path: "clients",
            element: <Clients />,
          },
          {
            path: "accomplished",
            element: <Accomplished />,
          },
          {
            path: "contact-us",
            element: <ContactUs />,
          },
          {
            path: "about-us",
            element: <AboutUs />,
          },
        ],
      },
    ],
  },
];
export default routes;
