import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Popover, styled } from "@mui/material";
import { IconWithText } from "../../../Components";
import { ServiceMenuItems } from "../../../Constant";
interface MenuItemWithPopoverProps {
  handleItemClick?: () => void;
  closeMobileDrawer?: () => void;
  textToShow: string;
}
const ServicesSubmenuContainer = styled("div")(({ theme }) => ({
  //border: "1px solid red",
  //height: "80px",
  //display: "flex",
  //flexDirection: "row",
  //justifyContent: "flex-start",
  //flexWrap: "wrap",
}));
const MenuItemWithPopover: React.FC<MenuItemWithPopoverProps> = ({
  handleItemClick,
  closeMobileDrawer,
  textToShow,
}) => {
  //ServicesSubMenu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSubMenuClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        sx={{ alignItems: "center", display: "inline-flex" }}
        onClick={handleSubMenuOpen}>
        {textToShow}
        <ExpandMoreIcon sx={{ fontSize: "medium" }} />
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleSubMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        {ServiceMenuItems.map((item) => {
          return (
            <ServicesSubmenuContainer onClick={closeMobileDrawer}>
              <IconWithText
                iconName={item.iconName}
                textValue={item.textValue}
                urlToNavigate={item.urlToNavigate}
                centerAlignNeeded={false}
                handleItemClick={handleItemClick}
              />
            </ServicesSubmenuContainer>
          );
        })}
      </Popover>
    </>
  );
};
export default MenuItemWithPopover;
