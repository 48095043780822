import {
  Grid,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Cloud } from "../Constant";

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "55%",
}));

const Box = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  padding: "35px",
  boxShadow: "0px 4px 4px 0px #0000007A",
}));

const Image = styled("img")(() => ({
  boxShadow: "0px 4px 4px 0px #0000007A",
}));

const CloudPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      {isMobile ? (
        <Grid
          sx={{
            backgroundColor: theme.palette.backgroundGrey.light,
            padding: "45px",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            direction={"column"}
            sx={{ alignItems: "center", paddingBottom: "15px" }}
          >
            <Typography
              variant="headingFont"
              sx={{ color: theme.palette.primary.dark }}
            >
              Our cloud deployment models
            </Typography>
            <Typography
              variant="cardContent"
              sx={{ color: theme.palette.greyColor.light }}
            >
              Cloud computing services and resources are provisioned, managed,
              and made available to users
            </Typography>
          </Grid>
          <Grid>
          {Cloud.map((item: any) => (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                backgroundColor: theme.palette.whiteColor.main,
                marginBottom: "40px",
                paddingBottom: "30px",
                boxShadow: "0px 4px 4px 0px #0000007A",
              }}
            >
              <Image src={item.image} />
              <Typography
                variant="menuFont"
                sx={{ color: theme.palette.primary.dark, padding: "20px" }}
              >
                {item.title}
              </Typography>
              <Typography variant="cardContent" sx={{ padding: "0px 30px" }}>{item.text}</Typography>
            </Grid>
          ))}
          </Grid>
          
        </Grid>
      ) : (
        <Grid
          sx={{
            backgroundColor: theme.palette.backgroundGrey.light,
            padding: "30px",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            direction={"column"}
            sx={{ alignItems: "center", paddingBottom: "15px" }}
          >
            <Typography
              variant="headingFont"
              sx={{ color: theme.palette.primary.dark }}
            >
              Our cloud deployment models
            </Typography>
            <Typography
              variant="cardContent"
              sx={{ color: theme.palette.greyColor.light }}
            >
              Cloud computing services and resources are provisioned, managed,
              and made available to users
            </Typography>
          </Grid>

          {Cloud.map((item: any, index: any) => (
            <Container
              sx={{ padding: index % 2 === 0 ? "10px 5%" : "20px 40%" }}
            >
              {index % 2 === 0 && (
                <Image src={item.image} sx={{ height: "auto" }} />
              )}
              <Box sx={{ backgroundColor: theme.palette.whiteColor.main }}>
                <Typography
                  variant="menuFont"
                  sx={{ color: theme.palette.primary.dark }}
                >
                  {item.title}
                </Typography>
                <Typography variant="cardContent">{item.text}</Typography>
              </Box>
              {index % 2 !== 0 && (
                <Image src={item.image} sx={{ height: "auto" }} />
              )}
            </Container>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default CloudPage;
