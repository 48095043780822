import { Grid } from "@mui/material";
import React from "react";
import { ImageWithText } from "../../Components";
import { MaintenancePageWelcomeMessage } from "../../Constant";

const MaintenanceAndSupportPage = () => {
  return (
    <Grid container direction={"column"}>
      <ImageWithText
        isTextAfterImage={true}
        isButtonNeeded={false}
        headerDescription={MaintenancePageWelcomeMessage.headerDescription}
        description={MaintenancePageWelcomeMessage.description}
        imageToShow={MaintenancePageWelcomeMessage.imageToShow}
      />
    </Grid>
  );
};

export default MaintenanceAndSupportPage;
