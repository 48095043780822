import React from "react";
import {
  OurPreferences,
  Sections,
  Industries,
  Benefits1,
  Benefits2,
} from "../../Constant";
import { Grid, Typography, styled, useTheme } from "@mui/material";
import { Preference } from "../../Assets";

const List = styled("ul")(() => ({}));

const OList = styled("ol")(() => ({}));

const ListItem = styled("li")(() => ({}));

const Image = styled("img")(() => ({
  paddingLeft: "15px",
  float: "left",
}));

const Preferences = () => {
  const theme = useTheme();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: theme.palette.darkBackGroundBlue.light,
          padding: "3% 6%",
        }}>
        <Typography
          variant="headingFont"
          sx={{ color: theme.palette.lightBlueText.main }}>
          Our Preferences
        </Typography>
        <List>
          {OurPreferences.map((item, index) => (
            <ListItem key={index} sx={{ color: theme.palette.whiteColor.main }}>
              <Typography variant="textMedium">{item}</Typography>
            </ListItem>
          ))}
          <OList>
            {Sections.map((item, index) => (
              <ListItem
                key={index}
                sx={{ color: theme.palette.whiteColor.main }}>
                <Typography variant="textMedium">{item}</Typography>
              </ListItem>
            ))}
          </OList>
        </List>
      </Grid>

      <Grid item xs={12} md={6} sx={{ padding: "3% 6%" }}>
        <Typography
          variant="headingFont"
          sx={{
            color: theme.palette.darkBackGroundBlue.dark,
            textWrap: "nowrap",
          }}>
          Industries that get benefits from us
        </Typography>
        <div className="square" style={{ paddingTop: "25px" }}>
          <div>
            <Image src={Preference} />
          </div>
          <Typography variant="textMedium">{Industries}</Typography>
        </div>
        <Grid
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            display: "flex",
            [theme.breakpoints.down("md")]: { paddingLeft: "40px" },
          }}>
          <List>
            {Benefits1.map((item, index) => (
              <ListItem key={index}>
                <Typography variant="textMedium">{item}</Typography>
              </ListItem>
            ))}
          </List>
          <List>
            {Benefits2.map((item, index) => (
              <ListItem key={index}>
                <Typography variant="textMedium">{item}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Preferences;
