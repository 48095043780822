import { Grid, Typography } from "@mui/material";
import React from "react";

const CoreTechnologiesPage = () => {
  return (
    <Grid container direction={"column"}>
      <Typography
        variant="headingFont"
        color="primary"
        sx={{ textAlign: "center", color: "#156CDD" }}>
        Technologies we are using
      </Typography>
      <Typography variant="bodyContentFont" paragraph>
        The choice of a tech stack for application development depends on
        various factors such as the type of application, scalability
        requirements, team expertise, budget constraints, and more. the specific
        needs of your project may require a different combination of
        technologies. Additionally, the tech landscape is always evolving, so
        it's crucial to stay updated on the latest tools and frameworks. We
        choose the proper technology stack for app development aligned with your
        product needs before the start prevents many issues. Making a smart
        decision at the beginning spares you the need to fix or update the
        product later.
      </Typography>
    </Grid>
  );
};

export default CoreTechnologiesPage;
