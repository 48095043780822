import React from "react";
import { Button, ButtonProps, Typography } from "@mui/material";

type Props = ButtonProps & {
  bgColor?: string;
  padding?: string | number;
  buttonText?: string;
  buttonFontSize?: number;
  onClickButton?: () => void;
  variant?: string;
  buttonTextColor?: string;
  buttonFontWeight?: number;
  btnWidth?: string | number;
  btnBorderRadius?: number;
  iconImage?: React.ReactNode;
  startIcon?: React.ReactNode;
  disabled?: boolean;
  backgroundImage?: string;
  border?: string;
};

const ButtonComponent = (props: Props) => {
  const {
    bgColor,
    padding,
    buttonText,
    buttonFontSize,
    variant = "contained",
    buttonTextColor = "#FFFFFF",
    buttonFontWeight,
    btnWidth,
    btnBorderRadius,
    onClickButton,
    iconImage,
    startIcon,
    backgroundImage,
    border,
    ...rest
  } = props;
  return (
    <Button
      sx={{
        display: "flex",
        width: btnWidth,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: bgColor || "#F39E0B",
        color: buttonTextColor,
        //fontSize: buttonFontSize || theme,
        //fontWeight: buttonFontWeight || 500,
        borderRadius: btnBorderRadius || 3,
        textTransform: "none",
        boxShadow: "none",
        padding: padding || "0.7rem 0.7rem",
        border: border,
        backgroundImage: backgroundImage || "",
        "& .MuiButton-contained": {
          boxShadow: "none",
        },
        transition: "transform 0.3s ease, background-color 0.3s ease", // Smooth transition for hover effect
        "&:hover": {
          transform: "scale(1.1)", // Zoom-in effect
        },
      }}
      disableElevation
      variant={variant}
      onClick={onClickButton}
      endIcon={iconImage}
      startIcon={startIcon}
      {...rest}>
      <Typography
        variant="buttonFont"
        sx={{
          transition: "transform 0.3s ease, background-color 0.3s ease", // Smooth transition for hover effect
          "&:hover": {
            transform: "scale(1)", // Zoom-in effect
          },
        }}>
        {buttonText}
      </Typography>
    </Button>
  );
};

export default ButtonComponent;
