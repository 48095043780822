import React, { FC } from "react";
import { Typography, Grid, SxProps, useTheme } from "@mui/material";

const ProjectCard: FC<{
  imgBgColor: string;
  imageUrl: string;
  cardHeaderText: string;
  platformText: string;
  backEndText: string;
  frontEndText: string;
  sx?: SxProps;
}> = ({
  imgBgColor,
  imageUrl,
  cardHeaderText,
  platformText,
  backEndText,
  frontEndText,
  sx,
}) => {
  const theme = useTheme();
  return (
    <Grid
      container
      flexDirection="column"
      sx={{
        minWidth: "320px",
        maxWidth: "450px",
        transition: "all 0.4s ease",
        ":hover": {
          ".card-Img": {
            transform: "rotate(20deg)",
          },
          ".card-header-text": {
            color: "#0097B5",
          },
        },
        ...sx,
      }}>
      <Grid
        item
        container
        className="card-Img"
        sx={{
          height: "450px",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          transition: "all 0.4s ease",
        }}>
        <Grid
          sx={{
            width: "300px",
            height: "300px",
            backgroundColor: imgBgColor,
            position: "absolute",
            borderRadius: 300 / 2,
            alignSelf: "center",
            justifySelf: "center",
            zIndex: -1,
          }}></Grid>
        <img
          src={imageUrl}
          alt="Phone"
          style={{ width: "auto", height: "auto" }}
        />
      </Grid>
      <Grid
        item
        container
        direction="column"
        rowGap={2}
        sx={{
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)",
          p: "10%",
          minHeight: "300px",
        }}>
        <Typography
          className="card-header-text"
          variant="subHeadingFont"
          sx={{ color: theme.palette.blackColor.main, fontWeight: 800 }}>
          {cardHeaderText}
        </Typography>
        <Typography variant="textMedium">Platform: {platformText}</Typography>
        <Typography variant="textMedium">Frontend: {frontEndText}</Typography>
        <Typography variant="textMedium">Backend: {backEndText}</Typography>
      </Grid>
    </Grid>
  );
};

export default ProjectCard;
