import { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { BannerTextConstant } from "../Constant";
import { BannerTextContext } from "../Context/BannerTextContext";

const ScrollToTopAndBannerUpdate = () => {
  const { pathname } = useLocation();
  const { ChangeBannerContent } = useContext(BannerTextContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    ChangeBannerContent(
        BannerTextConstant.filter(
          (item) => item.matchingRoute === pathname
        )[0]
      );
  }, [pathname]);
  return null;
};
export default ScrollToTopAndBannerUpdate;