import { Grid, Typography } from "@mui/material";
import React from "react";
import { ImageComp, ImageWithText } from "../../Components";
import {
  AndroidPageWelcomeMessage,
  androidAppDevelopMent,
} from "../../Constant";
import ProjectCard from "../../Components/ProjectCard";
import Preference from "./Preference";
import {
  NodeImage,
  tech14,
  tech17,
  tech19,
  tech20,
  tech22,
  tech23,
  TechImage10,
  TechImage2,
  TechImage4,
  TechImage5,
  TechImage6,
  TechImage7,
  TechImage8,
  TechImage9,
} from "../../Assets";
import FullWidthBox from "../../Components/FullWidthBox";

const AndroidPage = () => {
  return (
    <Grid container direction={"column"}>
      <ImageWithText
        isButtonNeeded={false}
        mainHeader={AndroidPageWelcomeMessage.heading1}
        mainHeaderLine2={AndroidPageWelcomeMessage.heading2}
        description={AndroidPageWelcomeMessage.description}
        description2={AndroidPageWelcomeMessage.description2}
        description3={AndroidPageWelcomeMessage.description3}
        imageToShow={AndroidPageWelcomeMessage.imageToShow}
      />
      <FullWidthBox>
        <Grid sx={{ paddingBottom: "40px" }}>
          <Preference />
        </Grid>
      </FullWidthBox>
      <Grid item xs container direction="column" alignItems="center">
        <Typography
          variant="headingFont"
          sx={{
            color: "#150E42",
          }}
        >
          Notable Projects of Android Application development
        </Typography>
        <Typography variant="subHeadingFont" sx={{ textAlign: "center" }}>
          Our application projects are well-planned, organized, and executed to{" "}
          <br />
          ensure success.
        </Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent="space-around"
        rowGap={4}
        sx={{ paddingY: "5%" }}
      >
        {androidAppDevelopMent.map((itm) => {
          return (
            <ProjectCard
              key={itm.cardHeader}
              imgBgColor={itm.imgBgColor}
              imageUrl={itm.imageUrl}
              cardHeaderText={itm.cardHeader}
              platformText={itm.platform}
              backEndText={itm.backEnd}
              frontEndText={itm.frontEnd}
            />
          );
        })}
      </Grid>
      <FullWidthBox
        backgroundColorValue="#F0F0F0"
        SxProps={{ marginBottom: "calc(3%*-1)" }}
      >
        <Grid sx={{ backgroundColor: "#F0F0F0", padding: "2%" }}>
          <Typography
            paragraph
            variant="bodyContentFont"
            sx={{ textAlign: "center" }}
          >
            <span style={{ color: "blue", textDecoration: "underline" }}>
              Core Technologies
            </span>{" "}
            used in Android Application Development
          </Typography>
          <ImageComp
            ImageList={[
              TechImage4,
              tech14,
              NodeImage,
              TechImage2,
              tech17,
              TechImage10,
              tech19,
              TechImage8,
              tech20,
              tech22,
              tech23,
              TechImage5,
              TechImage6,
              TechImage7,
              TechImage9,
            ]}
          ></ImageComp>
        </Grid>
      </FullWidthBox>
    </Grid>
  );
};

export default AndroidPage;
