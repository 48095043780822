import {
  Grid,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { WebPage } from "../Constant";
import { WebImage } from "../Assets";

const Image = styled("img")(() => ({
  maxWidth: "100%",
  height: "auto",
}));

const WebDesign = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      {isMobile ? (
        <Grid
          display={"flex"}
          direction={"column"}
          sx={{ alignItems: "center" }}
        >
          <Image src={WebImage} />
          {WebPage.map((item: any, index: any) => (
            <Grid
              sx={{
                boxShadow: "0px 4px 4px 0px #000000B8",
                margin: "8% 3%",
                padding: "4%",
              }}
            >
              <Image src={item.image} />
              <div
                style={{
                  display: "flex",
                  justifyContent: index % 2 !== 0 && "flex-end",
                  marginTop: "-60px",
                }}
              >
                <Typography
                  variant="menuFont"
                  sx={{
                    color: theme.palette.primary.dark,
                    padding: "20px",
                  }}
                >
                  {item.title}
                </Typography>
              </div>
              <Typography sx={{ padding: "20px 25px" }}>{item.text}</Typography>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            //width: "475px",
          }}
        >
          <Grid
            container
            direction={"column"}
            sx={{ alignItems: "center", paddingBottom: "15px" }}
          >
            <Typography
              variant="headingFont"
              sx={{ color: theme.palette.blackColor.main }}
            >
              Building a successful{" "}
              <span style={{ color: theme.palette.darkBackGroundBlue.light }}>
                web design
              </span>{" "}
              for web application
            </Typography>
            <Typography
              variant="cardContent"
              sx={{ color: theme.palette.greyColor.light }}
            >
              The field of web design is dynamic, so continuous learning and
              adaptation to new technologies and trends are essential for
              long-term success
            </Typography>
          </Grid>
          {/* <Image src={WebImage} /> */}
          <Grid
            container
            item
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {WebPage.map((item: any, index: any) => (
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "0px 4px 4px 0px #000000B8",
                  marginBottom: "4%",
                  paddingBottom: "30px",
                  width: "40%",
                }}
              >
                <Image src={item.image} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: index % 2 !== 0 && "flex-end",
                    marginTop: "-60px",
                  }}
                >
                  <Typography
                    variant="menuFont"
                    sx={{
                      color: theme.palette.primary.dark,
                      padding: "20px",
                    }}
                  >
                    {item.title}
                  </Typography>
                </div>
                <Typography variant="cardContent" sx={{ padding: "0px 30px" }}>
                  {item.text}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default WebDesign;
