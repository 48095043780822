import * as React from "react";
const ShowMoreIconSvg = ({ iconColor, handleIconClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={11}
    fill={iconColor}
    onClick={handleIconClick}
  >
    <path d="M0 .917C0 .41.497 0 1.111 0H18.89C19.503 0 20 .41 20 .917c0 .506-.497.916-1.111.916H1.11C.497 1.833 0 1.423 0 .917ZM0 5.5c0-.506.497-.917 1.111-.917H18.89c.614 0 1.111.41 1.111.917 0 .506-.497.917-1.111.917H1.11C.497 6.417 0 6.007 0 5.5ZM1.111 9.167c-.614 0-1.111.41-1.111.916C0 10.59.497 11 1.111 11H18.89c.614 0 1.111-.41 1.111-.917 0-.506-.497-.916-1.111-.916H1.11Z" />
  </svg>
);
export default ShowMoreIconSvg;
