import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Container, Box, Link, IconButton } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        py: "4px",
        px: 1,
        mt: "auto",
        //backgroundColor: theme.palette.whiteColor.main,
        backgroundColor: "#C9D1D5",
      }}>
      <Container maxWidth="sm" sx={{ textAlign: "center" }}>
        <Box>
          <IconButton
            component={Link}
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              borderBottom: "1px solid",
              borderColor: "#C9D1D5", //Dummy , hover effect does not impact neaarby component
              "&:hover": {
                borderBottom: "1px solid",
                borderColor: theme.palette.greyColor.light,
              },
            }}>
            <LinkedInIcon />
          </IconButton>
          <IconButton
            component={Link}
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              "&:hover": {
                borderBottom: "1px solid",
                borderColor: theme.palette.greyColor.light,
              },
            }}>
            <FacebookIcon />
          </IconButton>
          <IconButton
            component={Link}
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              "&:hover": {
                borderBottom: "1px solid",
                borderColor: theme.palette.greyColor.light,
              },
            }}>
            <TwitterIcon />
          </IconButton>
          <IconButton
            component={Link}
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              "&:hover": {
                borderBottom: "1px solid",
                borderColor: theme.palette.greyColor.light,
              },
            }}>
            <InstagramIcon />
          </IconButton>
          <IconButton
            component={Link}
            href="https://www.whatsapp.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              "&:hover": {
                borderBottom: "1px solid",
                borderColor: theme.palette.greyColor.light,
              },
            }}>
            <WhatsAppIcon />
          </IconButton>
        </Box>
        <Typography variant="footerContentFont" color="textSecondary">
          © Unitedhands Technologies Pvt Ltd 2023
        </Typography>
      </Container>
    </Box>
  );
};
export default Footer;
