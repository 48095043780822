import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { PhoneOutLine } from "../Assets";

interface CircleContainerProps {
  color?: string;
  marginLeft?: number;
}

const CircleContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "color" && prop !== "marginLeft",
})<CircleContainerProps>(({ theme, color, marginLeft }) => ({
  position: "relative",
  marginBottom: theme.spacing(2),
  marginLeft: marginLeft || theme.spacing(5),
  width: 300,
  height: 300,
  "&::before, &::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    width: "250px",
    height: "250px",
    borderRadius: "50%",
    backgroundColor: color || "rgba(255, 199, 133, 0.27)",
  },
  "&::before": {
    left: 0,
    clipPath: "polygon(0 0, 35% 0, 35% 100%, 0% 100%)",
  },
  "&::after": {
    right: 0,
    clipPath: "polygon(65% 0, 100% 0, 100% 100%, 65% 100%)",
  },
}));

type Props = {
  children?: JSX.Element | any;
  bgColor?: any;
  iconName?: any;
  marginLeft?: any;
};

const CircleComp = ({ children, bgColor, iconName, marginLeft }: Props) => {
  return (
    <Grid container sx={{ position: "relative", alignContent: "center" }}>
      <Grid item xs={12} md={3} sx={{ marginTop: 3 }}>
        <CircleContainer color={bgColor} marginLeft={marginLeft}>
          <img
            src={iconName || PhoneOutLine}
            alt="Phone Outline"
            style={{ width: "100%", height: "100%" }}
          />
          {children}
        </CircleContainer>
      </Grid>
    </Grid>
  );
};

export default CircleComp;
