import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/system";
import { PolygonBlueColor } from "../Assets";

const FlipCardContainer = styled(Box)(({ theme }) => ({
  perspective: "1000px",
}));

const CardInner = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "200px",
  height: "200px",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  transition: "transform 0.6s",
  transformStyle: "preserve-3d",
  //boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  //backgroundColor: "yellow",
  "&:hover": {
    cursor: "pointer",
    transform: "rotateY(180deg)",
  },
}));

const CardFace = styled(Box)(({ theme }) => ({
  //clipPath: "polygon(50% 0%, 100% 37%, 82% 100%, 18% 100%, 0% 37%)",
  //border: " 10px solid red",
  position: "absolute",
  width: "100%",
  height: "100%",
  backfaceVisibility: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  //boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
  backgroundImage: `url(${PolygonBlueColor})`,
  backgroundRepeat: "no-repeat",
}));

const CardFront = styled(CardFace)(({ theme }) => ({
  backgroundColor: theme.palette.whiteColor.main,
  //color: "black",
}));

const CardBack = styled(CardFace)(({ theme }) => ({
  backgroundColor: theme.palette.whiteColor.main,
  //color: "black",
  transform: "rotateY(180deg)",
}));
const OuterContainer = styled(Grid)(({ theme }) => ({
  padding: "20px 0px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const FlipCards = ({ heading, flipCardData }) => {
  const theme = useTheme();
  return (
    <OuterContainer>
      <Typography
        variant="headingFont"
        sx={{
          color: theme.palette.blackColor.main,
          padding: "20px 0px 30px 0px",
        }}>
        {heading}
      </Typography>
      <Box display="flex" flexWrap="wrap" justifyContent="center" gap={4}>
        {flipCardData.map((item, index) => (
          <FlipCardContainer key={index}>
            <CardInner className="cardInner">
              <CardFront>
                <img
                  src={item.imageToShowUrl}
                  alt={item.label}
                  style={{ width: "40%", height: "auto" }}
                />
              </CardFront>
              <CardBack>
                <Typography variant="menuFont">{item.label}</Typography>
              </CardBack>
            </CardInner>
          </FlipCardContainer>
        ))}
      </Box>
    </OuterContainer>
  );
};

export default FlipCards;
