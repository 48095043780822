import React, { FC } from "react";
import { Typography, Grid, SxProps } from "@mui/material";
import { styled } from "@mui/system";

const TextContainer = styled(Typography)(({theme}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 30%",
  textAlign: "start",
  color: "rgba(0, 0, 0, 0.76)",
  lineHeight: "250%",
  [theme.breakpoints.down("md")]: {
    padding: "0 10%",
  },
}));

const HeaderContainer = styled(Typography)(({ theme }) => ({
  color: theme.palette.whiteColor.main,
  lineHeight: "200%",
  margin: "0px",
  textAlign: "start",
  background: "rgba(0, 151, 178, 0.54)",
  position: "absolute",
  bottom: 0,
  right: 0,
  left: 0,
  paddingLeft: "17%",
  zIndex: -1,
  [theme.breakpoints.down("md")]: {
    textAlign: "start",
  },
}));

const ImageTextWithHeader: FC<{
  description: string;
  imageToShow: string;
  headerText: string;
  sx: SxProps;
}> = ({ description, imageToShow, headerText, sx }) => {
  return (
    <Grid container sx={{ position: "relative", ...sx }}>
      <Grid item xs={12} sm={7} container direction="column">
        <TextContainer variant="subHeadingFont">{description}</TextContainer>
        {headerText ? (
          <HeaderContainer variant="headingFont">{headerText}</HeaderContainer>
        ) : null}
      </Grid>
      <Grid item xs={12} sm container>
        <img
          src={imageToShow}
          alt="Laptop and phone"
          style={{ width: "100%", height: "auto" }}
        />
      </Grid>
    </Grid>
  );
};

export default ImageTextWithHeader;
