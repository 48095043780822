// ExpandableComponent.js
import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: "30px",
  boxShadow: theme.shadows[1],

  transition: "all 0.9s",
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
  "&:first-of-type": {
    borderTopRightRadius: "30px",
  },
  "&:last-of-type": {
    borderBottomRightRadius: "30px",
  },
 "&:before": {
  backgroundColor: theme.palette.backgroundBlue.main,
 }
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.darkBackGroundBlue.main,
  color: theme.palette.backgroundBlue.main,
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderTopRightRadius: "30px",
  borderBottomRightRadius: "30px",
  "&:hover": {
    backgroundColor: theme.palette.darkBackGroundBlue.main,
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.backgroundBlue.main,
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderTopRightRadius: "30px",
  borderBottomRightRadius: "30px",
}));

const HeadingDescriptionAccordian = ({ items }) => {
  const [expanded, setExpanded] = useState(null);

  const handleMouseEnter = (panel) => (event) => {
    setExpanded(panel);
  };

  const handleMouseLeave = () => {
    setExpanded(null);
  };
  return (
    <Container style={{ paddingLeft: "0px" }}>
      {items.map((item, index) => (
        <CustomAccordion
          key={index}
          expanded={expanded === `panel${index}`}
          onMouseEnter={handleMouseEnter(`panel${index}`)}
          onMouseLeave={handleMouseLeave}
        >
          <CustomAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`panel${index}-header`}
          >
            <Typography variant="buttonFont">{item.heading}</Typography>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <Typography variant="bodyContentFont">
              {item.description}
            </Typography>
          </CustomAccordionDetails>
        </CustomAccordion>
      ))}
    </Container>
  );
};

export default HeadingDescriptionAccordian;
