import { createTheme, responsiveFontSizes } from "@mui/material/styles/index";
import React from "react";

const defaultColors = {
  BLUE: {
    900: "#004AAD",
    800: "#7382BC",
    700: "#0097B2",
    300: "#F0F3FB",
    200: "#D9E1FF",
    600: "#0c243c",
    100: "#DFF4FF",
    500: "#310479",
    50: "#07D9FF",
    400: "#044AAD",
  },
  GREY: {
    900: "#B0B0B0",
    700: "#3D3C3C",
    600: "#c9c1d5",
    200: "#F0F0F0",
  },
  WHITE: {
    900: "#ffffff",
  },
  BLACK: {
    900: "#000000",
  },
};
const Colors = {
  primary: defaultColors.BLUE[900],
  blueSecondary: defaultColors.BLUE[800],
  backGroundGrey: defaultColors.GREY[700],
  textGrey: defaultColors.GREY[900],
  pureWhite: defaultColors.WHITE[900],
  pureBlack: defaultColors.BLACK[900],
  lightBlue: defaultColors.BLUE[200],
  mediumBlue: defaultColors.BLUE[700],
  veryLightBlue: defaultColors.BLUE[300],
  darkNavyBlue: defaultColors.BLUE[600],
  backgroundGrey: defaultColors.GREY[600],
  backgroundGreyLight: defaultColors.GREY[200],
  skyBlue: defaultColors.BLUE[100],
  violetBlue: defaultColors.BLUE[500],
  brightBlue: defaultColors.BLUE[50],
  royalBlue: defaultColors.BLUE[400],
};

const fontWeight = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  mediumBold: 600,
  bold: 700,
  black: 900,
};

export const MetricsSizes = {
  tiny: 4,
  tiny_x: 6,
  tiny_xx: 8,
  tiny_xxx: 10,

  small: 13,
  small_x: 12,
  small_xx: 14,
  small_xxx: 16,

  regular: 18,
  regular_x: 20,
  regular_xx: 22,
  regular_xxx: 24,

  medium: 26,
  medium_x: 28,
  medium_xx: 30,
  medium_xxx: 32,

  large: 34,
  large_x: 36,
  large_xx: 40,
  large_xxx: 48,

  x_large: 52,
};

export const Breakpoints = {
  values: {
    xs: 400,
    sm: 680,
    md: 1024,
    lg: 1280,
    xl: 1920,
  },
};

declare module "@mui/material/styles" {
  interface Palette {
    greyColor: Palette["primary"];
    whiteColor: Palette["primary"];
    blackColor: Palette["primary"];
    backgroundBlue: Palette["primary"];
    darkBackGroundBlue: Palette["primary"];
    backgroundGrey: Palette["primary"];
    lightBlueText: Palette["primary"];
  }

  interface PaletteOptions {
    greyColor?: PaletteOptions["primary"];
    whiteColor: PaletteOptions["primary"];
    blackColor: PaletteOptions["primary"];
    backgroundBlue: PaletteOptions["primary"];
    darkBackGroundBlue: PaletteOptions["primary"];
    backgroundGrey: PaletteOptions["primary"];
    lightBlueText: PaletteOptions["primary"];
  }
  interface TypographyVariants {
    menuFont: React.CSSProperties;
    mobileMenuFont: React.CSSProperties;
    footerContentFont?: React.CSSProperties;
    bannerText_OnlyTextLeftAligned: React.CSSProperties;
    headingFont: React.CSSProperties;
    bodyContentFont: React.CSSProperties;
    subHeadingFont: React.CSSProperties;
    descriptionBold: React.CSSProperties;
    textMedium: React.CSSProperties;
    buttonFont?: React.CSSProperties;
    subMenuFont?: React.CSSProperties;
    cardHeading?: React.CSSProperties;
    cardContent?: React.CSSProperties;
    bannerText_HeadingImageCenterAlign_Heading?: React.CSSProperties;
    bannerText_HeadingImageCenterAlign_Description?: React.CSSProperties;
    bannerText_smallSizeDescription?: React.CSSProperties;
    bannerText_HeadingLeftImageRightAlign_Heading?: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    menuFont?: React.CSSProperties;
    mobileMenuFont?: React.CSSProperties;
    footerContentFont?: React.CSSProperties;
    bannerText_OnlyTextLeftAligned?: React.CSSProperties;
    headingFont?: React.CSSProperties;
    bodyContentFont?: React.CSSProperties;
    subHeadingFont?: React.CSSProperties;
    descriptionBold?: React.CSSProperties;
    textMedium?: React.CSSProperties;
    buttonFont?: React.CSSProperties;
    subMenuFont?: React.CSSProperties;
    cardHeading?: React.CSSProperties;
    cardContent?: React.CSSProperties;
    bannerText_HeadingImageCenterAlign_Heading?: React.CSSProperties;
    bannerText_HeadingImageCenterAlign_Description?: React.CSSProperties;
    bannerText_smallSizeDescription?: React.CSSProperties;
    bannerText_HeadingLeftImageRightAlign_Heading?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    menuFont: true;
    mobileMenuFont: true;
    footerContentFont: true;
    bannerText_OnlyTextLeftAligned: true;
    headingFont: true;
    bodyContentFont: true;
    subHeadingFont: true;
    descriptionBold: true;
    textMedium: true;
    buttonFont: true;
    subMenuFont: true;
    cardHeading: true;
    cardContent: true;
    bannerText_HeadingImageCenterAlign_Heading: true;
    bannerText_HeadingImageCenterAlign_Description: true;
    bannerText_smallSizeDescription: true;
    bannerText_HeadingLeftImageRightAlign_Heading: true;
  }
}
let pureLightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 500,
      sm: 700,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: Colors.primary,
      light: Colors.blueSecondary,
      dark: Colors.mediumBlue,
    },
    greyColor: {
      light: Colors.backGroundGrey,
      main: Colors.textGrey,
    },
    whiteColor: {
      main: Colors.pureWhite,
    },
    blackColor: {
      main: Colors.pureBlack,
    },
    backgroundBlue: {
      main: Colors.lightBlue,
      light: Colors.veryLightBlue,
      dark: Colors.skyBlue,
    },
    darkBackGroundBlue: {
      main: Colors.darkNavyBlue,
      dark: Colors.violetBlue,
      light: Colors.royalBlue,
    },
    backgroundGrey: {
      main: Colors.backgroundGrey,
      light: Colors.backgroundGreyLight,
    },
    lightBlueText: {
      main: Colors.brightBlue
    }
  },
  typography: {
    fontFamily: "Mukta",
    menuFont: {
      fontWeight: fontWeight.mediumBold,
      fontSize: MetricsSizes.regular_xxx,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.regular_xx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.regular_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.regular_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.regular,
      },
      [`@media screen and (max-width: ${Breakpoints.values.xs}px)`]: {
        fontSize: MetricsSizes.small_xxx,
      },
    },
    mobileMenuFont: {
      fontWeight: fontWeight.bold,

      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.regular_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.regular,
      },
      [`@media screen and (max-width: ${Breakpoints.values.xs}px)`]: {
        fontSize: MetricsSizes.small_xx,
      },
    },
    footerContentFont: {
      fontWeight: fontWeight.regular,
      fontSize: MetricsSizes.small_xx,
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.small_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.small_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.xs}px)`]: {
        fontSize: MetricsSizes.small_xx,
      },
    },
    bannerText_OnlyTextLeftAligned: {
      fontWeight: fontWeight.bold,
      fontSize: MetricsSizes.large_xxx,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.large,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.medium_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.medium_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small_xx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.xs}px)`]: {
        fontSize: MetricsSizes.small_xx,
      },
    },
    headingFont: {
      color: Colors.primary,
      fontWeight: fontWeight.mediumBold,
      fontSize: MetricsSizes.large_x,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.medium_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.medium,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.medium,
        textAlign: "center",
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.regular_xx,
        textAlign: "center",
      },
      [`@media screen and (max-width: ${Breakpoints.values.xs}px)`]: {
        fontSize: MetricsSizes.regular,
        textAlign: "center",
      },
    },
    bodyContentFont: {
      fontWeight: fontWeight.regular,
      fontSize: MetricsSizes.regular_xx,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.regular,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.regular,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.regular,
        textAlign: "center",
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small_xxx,
        textAlign: "center",
      },
      [`@media screen and (max-width: ${Breakpoints.values.xs}px)`]: {
        fontSize: MetricsSizes.small_xx,
        textAlign: "center",
      },
    },
    subHeadingFont: {
      color: Colors.mediumBlue,
      fontWeight: fontWeight.mediumBold,
      fontSize: MetricsSizes.regular_xxx,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.regular_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.regular_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.regular_x,
        textAlign: "center",
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small_xxx,
        textAlign: "center",
      },
    },
    descriptionBold: {
      fontWeight: fontWeight.mediumBold,
      fontSize: MetricsSizes.regular_xxx,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.regular_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.small_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.small_xx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.tiny_xx,
      },
    },
    buttonFont: {
      //color: Colors.pureWhite,
      fontWeight: fontWeight.mediumBold,
      fontSize: MetricsSizes.regular_xx,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.regular,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.small_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.small_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small_xx,
      },
    },
    textMedium: {
      fontWeight: fontWeight.medium,
      fontSize: MetricsSizes.regular_x,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.regular_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.regular,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.regular,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small_xx,
      },
    },
    cardHeading: {
      fontWeight: fontWeight.mediumBold,
      fontSize: MetricsSizes.regular_x,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.regular,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.small_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.small_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small_xx,
      },
    },
    cardContent: {
      fontWeight: fontWeight.medium,
      fontSize: MetricsSizes.regular,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.small_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.small_xx,
        textAlign: "center",
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.small_xx,
        textAlign: "center",
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small,
        textAlign: "center",
      },
    },
    subMenuFont: {
      fontWeight: fontWeight.medium,
      fontSize: MetricsSizes.regular_xx,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.small_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.small_xx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.small_xx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small_x,
      },
    },
    bannerText_HeadingImageCenterAlign_Heading: {
      fontWeight: fontWeight.bold,
      textAlign: "center",
      fontSize: MetricsSizes.large_xxx,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.large,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.medium_xx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.medium_xx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.xs}px)`]: {
        fontSize: MetricsSizes.small_x,
      },
    },
    bannerText_HeadingImageCenterAlign_Description: {
      fontWeight: fontWeight.bold,
      textAlign: "center",
      fontSize: MetricsSizes.large_xx,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.medium_xxx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.medium_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.medium_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.xs}px)`]: {
        fontSize: MetricsSizes.small_x,
      },
    },
    bannerText_smallSizeDescription: {
      fontWeight: fontWeight.regular,
      textAlign: "left",
      fontSize: MetricsSizes.regular_xxx,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.regular_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.regular,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.regular,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.xs}px)`]: {
        fontSize: MetricsSizes.tiny_xx,
      },
    },
    bannerText_HeadingLeftImageRightAlign_Heading: {
      fontWeight: fontWeight.bold,
      textAlign: "left",
      fontSize: MetricsSizes.large_xxx,
      [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
        fontSize: MetricsSizes.large,
      },
      [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
        fontSize: MetricsSizes.medium_xx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
        fontSize: MetricsSizes.medium_xx,
      },
      [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
        fontSize: MetricsSizes.small_x,
      },
      [`@media screen and (max-width: ${Breakpoints.values.xs}px)`]: {
        fontSize: MetricsSizes.small_x,
      },
    },
  },
});
export const PureLightTheme = responsiveFontSizes(pureLightTheme);
