import { Grid } from "@mui/material";
import React from "react";
import { ImageWithText } from "../../Components";
import { cloudPageWelcomeMessage } from "../../Constant";
import CloudPage from "../../Components/CloudComponent";
import FullWidthBox from "../../Components/FullWidthBox";

const CloudApplicationPage = () => {
  return (
    <Grid container direction={"column"}>
      <ImageWithText
        isTextAfterImage={true}
        isButtonNeeded={false}
        mainHeader={cloudPageWelcomeMessage.heading1}
        mainHeaderLine2={cloudPageWelcomeMessage.heading2}
        description={cloudPageWelcomeMessage.description}
        description2={cloudPageWelcomeMessage.description2}
        description3={cloudPageWelcomeMessage.description3}
        imageToShow={cloudPageWelcomeMessage.imageToShow}
      />
      <FullWidthBox>
        <Grid>
          <CloudPage />
        </Grid>
      </FullWidthBox>
    </Grid>
  );
};

export default CloudApplicationPage;
