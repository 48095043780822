import { useState, createContext } from "react";
import * as React from "react";
import { BannerTextConstant } from "../Constant";

type BannerTextContextInterface = {
  bannerDetails: any;
  ChangeBannerContent: (url) => void;
};

export const BannerTextContext = createContext<BannerTextContextInterface>(
  {} as BannerTextContextInterface
);

export const BannerTextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [bannerText, setBannerText] = useState(
    BannerTextConstant.filter((item) => item.matchingRoute === "/home")[0]
  );
  const contextValue = React.useMemo(() => {
    return {
      bannerDetails: bannerText,
      ChangeBannerContent: setBannerText,
    };
  }, [bannerText, setBannerText]);

  return (
    <BannerTextContext.Provider value={contextValue}>
      {children}
    </BannerTextContext.Provider>
  );
};
