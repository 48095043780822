import React from "react";
import { Grid, styled } from "@mui/material";

import {
  ImageWithText,
  ImageTextWithHeader,
  TechListComp,
} from "../../Components";

import {
  HomePageNotableProjects,
  HomePageWelcomeMessage,
  notableProjects,
  servicesWeOfferData,
} from "../../Constant";
import IcomImageWithDescriptionCard from "../../Components/IcomImageWithDescriptionCard";
import {
  TechImage1,
  TechImage10,
  TechImage2,
  TechImage3,
  TechImage4,
  TechImage5,
  TechImage6,
  TechImage7,
  TechImage8,
  TechImage9,
  Trigent,
  Technoduce,
  Auxesis,
  TechMahindra,
  Target_24,
  Jll,
  seyone,
  Bosch,
  Es,
  Tdp,
} from "../../Assets/Images";
import ProjectCard from "../../Components/ProjectCard";
import InfoCardWithImage from "../../Components/InfoCardWithImage";

const ServicesOfferedContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 3,
  flexWrap: "wrap",
  alignItems: "stretch",

  [theme.breakpoints.up("sm")]: {
    justifyContent: "space-between",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

const HomePage = () => {
  return (
    <Grid container direction={"column"}>
      <ImageWithText
        heading1={HomePageWelcomeMessage.heading1}
        heading2={HomePageWelcomeMessage.heading2}
        subHeading={HomePageWelcomeMessage.subHeading}
        description={HomePageWelcomeMessage.description}
        imageToShow={HomePageWelcomeMessage.imageToShow}
        isButtonNeeded={HomePageWelcomeMessage.isButtonNeeded}
        buttonText={HomePageWelcomeMessage.buttonText}
      />
      <TechListComp
        listHeader="Our Clients"
        ImageList={[
          Trigent,
          Technoduce,
          Auxesis,
          TechMahindra,
          Target_24,
          Jll,
          seyone,
          Bosch,
          Es,
          Tdp,
        ]}
        sx={{ paddingY: "5%" }}></TechListComp>
      <ServicesOfferedContainer>
        {servicesWeOfferData.map((item) => (
          <IcomImageWithDescriptionCard {...item} />
        ))}
      </ServicesOfferedContainer>
      <TechListComp
        listHeader="Core Technologies"
        ImageList={[
          TechImage1,
          TechImage2,
          TechImage8,
          TechImage4,
          TechImage9,
          TechImage6,
          TechImage5,
          TechImage7,
          TechImage3,
          TechImage10,
        ]}
        sx={{ paddingY: "5%" }}></TechListComp>
      <ImageTextWithHeader
        sx={{ paddingTop: "2%" }}
        description={HomePageNotableProjects.description}
        imageToShow={HomePageNotableProjects.imageToShow}
        headerText={HomePageNotableProjects.headerText}></ImageTextWithHeader>
      <Grid
        item
        rowGap={4}
        container
        justifyContent="space-around"
        sx={{ paddingY: "5%" }}>
        {notableProjects.map((itm, index) => {
          return (
            <ProjectCard
              key={index}
              imgBgColor={itm.imgBgColor}
              imageUrl={itm.imageUrl}
              cardHeaderText={itm.cardHeader}
              platformText={itm.platform}
              backEndText={itm.backEnd}
              frontEndText={itm.frontEnd}
            />
          );
        })}
      </Grid>
      <InfoCardWithImage />
    </Grid>
  );
};
export default HomePage;
