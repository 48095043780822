import React from "react";
import {
  AndroidWelcomeImage,
  B2cProj,
  CloudWelcomeImage,
  CompanyImage,
  GroupImage,
  iosAppleLogoWhite,
  IosWelcomeImage,
  LaptopMobile,
  MaintenanceWelcomeImage,
  ParcelDelProj,
  PizzaProj,
  OthersWelcomeImage,
  WebDevWelcomeImage,
  WasteManProj,
  ItStaffingWelcomeImage,
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  idea,
  idea2,
  idea3,
  idea4,
  idea5,
  Cloud1,
  Cloud2,
  UIImage,
  UXImage,
  Cloud3,
} from "../Assets";
import AppleIcon from "@mui/icons-material/Apple";
import AdbIcon from "@mui/icons-material/Adb";
import DoneIcon from "@mui/icons-material/Done";
import CodeIcon from "@mui/icons-material/Code";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import ViewListIcon from "@mui/icons-material/ViewList";
import PublicIcon from "@mui/icons-material/Public";
import { Breakpoints, MetricsSizes } from "../Theme/schemes/PureLightTheme";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import PeopleIcon from "@mui/icons-material/People";
import * as otherServicesCardsImages from "../Assets/Images/OtherServicesCardsImages/index";
import * as otherServicesDescriptiveCardImages from "../Assets/Images/OtherServicesCardIcons/index";

const BasePath = "http://localhost:3000/";
const BannerTextConstant = [
  {
    matchingRoute: "/home",
    displayModel: "OnlyTextLeftAligned",
    textValue: ["Hands held in unity", "form an unbreakable bond of Success."],
  },
  {
    matchingRoute: "/ios",
    displayModel: "HeadingImageCenterAlign",
    textValue:
      "Our iOS app development services meets user experience to deliver solutions that exceed expectations.",
    headingText: "What We Offer",
    imagesource: (
      <AppleIcon
        sx={{
          fontSize: MetricsSizes.regular_xx * 3,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
  },
  {
    matchingRoute: "/android",
    displayModel: "HeadingImageCenterAlign",
    textValue:
      "We ensures a comprehensive approach to app development, resulting in a high-quality and successful Android application.",
    headingText: "What We Offer",
    imagesource: (
      <AdbIcon
        sx={{
          fontSize: MetricsSizes.regular_xx * 3,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
  },
  {
    matchingRoute: "/it-staffing-solutions",
    displayModel: "HeadingImageCenterAlign",
    textValue:
      "Our IT staffing solutions are designed to help IT companies like yours build strong, skilled, and dynamic teams. ",
    headingText: "We have the expertise.",
    imagesource: (
      <PeopleIcon
        sx={{
          fontSize: MetricsSizes.regular_xx * 3,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
  },
  {
    matchingRoute: "/maintenance-support",
    displayModel: "HeadingImageCenterAlign",
    textValue:
      "We contribute to the overall health and sustainability of the application, making it easier to maintain, support, and evolve over time.",
    headingText: "How We Support",
    imagesource: (
      <DoneIcon
        sx={{
          fontSize: MetricsSizes.regular_xx * 3,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
  },
  {
    matchingRoute: "/cloud-application",
    displayModel: "HeadingImageCenterAlign",
    textValue:
      "We ensure that the applications are scalable, resilient, and take full advantage of cloud services.",
    headingText: "What We Offer",
    imagesource: (
      <CloudSyncIcon
        sx={{
          fontSize: MetricsSizes.regular_xx * 3,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
  },
  {
    matchingRoute: "/other-services",
    displayModel: "HeadingImageCenterAlign",
    textValue: "We also offer a range of additional services.",
    headingText: "Other Services",
    imagesource: (
      <ViewListIcon
        sx={{
          fontSize: MetricsSizes.regular_xx * 3,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
  },
  {
    matchingRoute: "/web-development",
    displayModel: "HeadingImageCenterAlign",
    textValue:
      "Our developers often follow a systematic process to ensure the successful creation, deployment, and maintenance of the web application.",
    headingText: "What We Offer",
    imagesource: (
      <PublicIcon
        sx={{
          fontSize: MetricsSizes.regular_xx * 3,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
  },
  {
    matchingRoute: "/core-technologies",
    displayModel: "HeadingImageCenterAlign",
    textValue:
      "We utilize many world-class cutting-edge technologies to build and further enhance our products.",
    headingText: "Core Technologies",
    imagesource: (
      <CodeIcon
        sx={{
          fontSize: MetricsSizes.regular_xx * 3,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 3,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
  },
  {
    matchingRoute: "/clients",
    displayModel: "HeadingLeftImageRightAlign",
    headingText: "Our Clients",
    imagesource: (
      <HandshakeIcon
        sx={{
          fontSize: MetricsSizes.regular_xx * 8,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 8,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 6,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 4,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 4,
          },
        }}
      />
    ),
    textValue:
      "Prioritizing client relationships not only drives immediate revenue but also contributes to sustained growth, brand strength, and adaptability in a dynamic business environment. We understand that  clients' needs are better positioned for long-term success.",
  },
  {
    matchingRoute: "/accomplished",
    displayModel: "HeadingLeftImageRightAlign",
    headingText: "Our Accomplished Projects ",
    imagesource: (
      <FolderSpecialIcon
        sx={{
          fontSize: MetricsSizes.regular_xx * 8,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 8,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 6,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 4,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 4,
          },
        }}
      />
    ),
    textValue:
      "We keep the portfolio visually appealing, easy to navigate, and regularly updated with the latest projects and achievements. We develop projects that involves a systematic and collaborative approach to ensure successful outcomes.",
  },
  {
    matchingRoute: "/about-us",
    displayModel: "CenterWithSubText",
    headingText: "About Unitedhands Technologies",
    textValue:
      "Beyond Imagination, Beyond Code: Transformative Software, Exceptional Results.",
  },
  {
    matchingRoute: "/contact-us",
    displayModel: "CenterWithSubText",
    headingText: "We're here",
    imagesource: iosAppleLogoWhite,
    textValue:
      "What to get in touch? We'd like to hear from you. Here's how you can reach us..",
  },
];

const ServiceMenuItems = [
  {
    iconName: (
      <AppleIcon
        color="primary"
        sx={{
          fontSize: MetricsSizes.regular_xx * 2,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
    textValue: "iOS App Development",
    urlToNavigate: "/ios",
  },
  {
    iconName: (
      <AdbIcon
        color="primary"
        sx={{
          fontSize: MetricsSizes.regular_xx * 2,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
    textValue: "Android App Development",
    urlToNavigate: "/android",
  },
  {
    iconName: (
      <DoneIcon
        color="primary"
        sx={{
          fontSize: MetricsSizes.regular_xx * 2,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
    textValue: "Maintenance and Support",
    urlToNavigate: "/maintenance-support",
  },
  {
    iconName: (
      <PeopleIcon
        color="primary"
        sx={{
          fontSize: MetricsSizes.regular_xx * 2,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
    textValue: "IT Staffing Solutions",
    urlToNavigate: "/it-staffing-solutions",
  },
  {
    iconName: (
      <CodeIcon
        color="primary"
        sx={{
          fontSize: MetricsSizes.regular_xx * 2,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
    textValue: "Core Technologies",
    urlToNavigate: "/core-technologies",
  },
  {
    iconName: (
      <CloudSyncIcon
        color="primary"
        sx={{
          fontSize: MetricsSizes.regular_xx * 2,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
    textValue: "Cloud App Development",
    urlToNavigate: "/cloud-application",
  },
  {
    iconName: (
      <PublicIcon
        color="primary"
        sx={{
          fontSize: MetricsSizes.regular_xx * 2,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
    textValue: "Web Development",
    urlToNavigate: "/web-development",
  },
  {
    iconName: (
      <ViewListIcon
        color="primary"
        sx={{
          fontSize: MetricsSizes.regular_xx * 2,
          [`@media screen and (max-width: ${Breakpoints.values.xl}px)`]: {
            fontSize: MetricsSizes.small_xxx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.lg}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            fontSize: MetricsSizes.small_xx * 2,
          },
          [`@media screen and (max-width: ${Breakpoints.values.sm}px)`]: {
            fontSize: MetricsSizes.small_x * 2,
          },
        }}
      />
    ),
    textValue: "Other Services",
    urlToNavigate: "/other-services",
  },
];

const HomePageWelcomeMessage = {
  heading1: "Welcome to",
  heading2: " Unitedhands Technologies",
  subHeading: "Custom Software Development",
  description:
    "At our company, we are a digital transformation consultancy and engineering company that delivers cutting edge solutions for global organizations and technology startups. We specialize in delivering robust custom web and mobile apps solutions to fit your unique business needs.",
  imageToShow: LaptopMobile,
  isButtonNeeded: true,
  buttonText: "Explore",
};

const IosPageWelcomeMessage = {
  heading1: "Transforming Visions into Stunning iOS Apps where",
  heading2: "Innovation Meets Expertise",
  description:
    "iOS app development is an iterative process that involves continuous improvement and adaptation to user needs and market changes. Developers often work in teams, with roles such as developers, designers, testers, and project managers collaborating to bring the app to fruition. ",
  description2:
    "In iOS application development, we provide various components, frameworks, or functionalities that are used to provide specific features or capabilities within an iOS app. These services can be built-in iOS services, third-party services, or custom services developed by app developers. We often combine these services to create feature-rich and user-friendly applications for iPhones, iPads, and other Apple devices.",
  description3:
    "We give  importance to provide information that showcases the expertise, experience, and the value to our potential clients. Our team ensures your app remains ahead of the competition in the industry trends and also stays up-to-date with the latest iOS technologies such as Swift, Objective C or cross platform like Flutter, React Native, or Kotlin.",
  imageToShow: IosWelcomeImage,
};

const cloudPageWelcomeMessage = {
  heading1:
    "Our strategic and cloud-agnostic approach enables freedom in application ",
  heading2: "deployment, innovation, and seamless scaling.",
  description:
    "Cloud application development refers to the process of creating software applications that are hosted and run on cloud computing platforms. This approach offers several advantages, including flexibility, scalability, and cost-effectiveness.",
  description2:
    "Cloud application development requires a holistic approach that considers the unique features and services of the chosen cloud provider.  We focus on building features and functionality while leveraging the benefits of cloud infrastructure and services.",
  description3:
    "Developing cloud applications involves a set of best practices and considerations to ensure that the applications are scalable, resilient, and take full advantage of cloud services. We Acquire a solid understanding of cloud computing concepts, including IaaS, PaaS, SaaS, and different cloud deployment models (public, private, hybrid).  We Explore serverless options for specific workloads, functions, or event-driven components to reduce infrastructure management overhead.",
  imageToShow: CloudWelcomeImage,
};

const AndroidPageWelcomeMessage = {
  heading1:
    "Crafting high-quality Android apps which have blend of creativity, technical",
  heading2: "prowess, and relentless pursuit of perfection ",
  description:
    "Android application development is a dynamic field that requires ongoing learning and adaptation to stay up-to-date with the latest Android versions, tools, and best practices. We’ re building a small utility app or a complex, feature-rich application, offers a versatile platform for creating a wide range of mobile solutions.",
  description2:
    "When developing an Android app, we provide several essential components and content that need to be included to ensure a complete and functional application. Android app that we develop value to the users and meets their needs effectively. The specific requirements may vary depending on the app's purpose and complexity.",
  description3:
    "We developing an Android app involves a systematic process of planning, coding, testing, and deployment. Our developers always be open to feedback, continuously improve the app, and adapt to changing user needs and technology trends. We Collaborate with designers, testers, and other stakeholders  for building a successful Android app.",
  imageToShow: AndroidWelcomeImage,
};
const WebPageWelcomeMessage = {
  heading1:
    "At Unitedhands Technologies, we offers multi-functional web portals that can",
  heading2: "help our clients to record ROI-driven results.",
  description:
    "Developing a web application involves several key steps, and developers often follow a systematic process to ensure the successful creation, deployment, and maintenance of the application. First, we understand the purpose and objectives of the web application and identify and document functional and non-functional requirements.",
  description2:
    "We create a project plan, outlining tasks, milestones,responsibilities and implement security measures, including data encryption, input validation, and protection against common vulnerabilities (e.g., SQL injection, XSS).",
  description3:
    "We use tools for monitoring application performance and user behavior. Throughout this process, we made effective communication among team members, adherence to coding standards, and a commitment to quality assurance are crucial. Agile methodologies, such as Scrum or Kanban, can also be employed to manage development in iterative cycles and respond to changing requirements.",
  imageToShow: WebDevWelcomeImage,
};
const MaintenancePageWelcomeMessage = {
  headerDescription:
    "Once an application is deployed, it requires ongoing attention to address issues, implement updates, and provide assistance to users. By effectively managing application maintenance and support, organizations can ensure the reliability, security, and sustainability of their software applications throughout their lifecycle. Our Developers play a crucial role in maintaining and supporting applications throughout their lifecycle. When seeking application maintenance services, organizations may engage with dedicated teams or outsourcing partners specializing in these areas. The goal is to ensure that the application remains secure, reliable, and aligned with evolving business needs over time.  ",
  description:
    "We involve a series of activities aimed at addressing issues, making enhancements, and optimizing performance.  Effective application maintenance ensures the longevity, reliability, and relevance of the software, contributing to a positive user experience and supporting the business's objectives. By providing comprehensive application support services, organizations can ensure that their applications meet user expectations, remain secure, and contribute to the overall success of the business. The goal is to minimize disruptions, address issues promptly, and continuously improve the user experience.",
  imageToShow: MaintenanceWelcomeImage,
};
const OthersPageWelcomeMessage = {
  description:
    "We also help you with  Data analysis , which is a process of inspecting, cleaning, transforming, and modeling data with the goal of discovering useful information, drawing conclusions, and supporting decision-making. It involves a variety of techniques and methods to extract meaningful insights from raw data. It is multifaceted process that involves transforming raw data into meaningful insights to support informed decision-making. The specific techniques and tools used can vary depending on the nature of the data and the goals of the analysis. By data analysis we empowers organizations to make evidence-based decisions, enhance efficiency, and gain a competitive edge in the ever-evolving business landscape. We make your data more valuable and create advanced data products for intelligent business decisions",
  imageToShow: OthersWelcomeImage,
};
const ItStaffingPageWelcomeMessage = {
  heading1: "It all begins with effective candidate sourcing.",
  heading2: "Empowering Your Business with Top Talent.",
  description:
    "In the ever-evolving world of technology, having the right team is crucial for staying ahead. Our IT staffing solutions are designed to help IT companies like yours build strong, skilled, and dynamic teams. Whether you need temporary support for a specific project, contract-to-hire, or permanent placements, we have the expertise to meet your needs.",
  description2:
    "For projects that require specific expertise for a defined period, our contract staffing services provide you with highly skilled professionals on a temporary basis. This ensures you have the right talent when you need it without the long-term commitment.",
  description3:
    "For critical projects requiring specialized skills, we provide project-based staffing solutions. Our experts work closely with your team to deliver successful outcomes, ensuring your project is completed on time and within budget.",
  imageToShow: ItStaffingWelcomeImage,
};
const AboutUsMission = {
  heading1: "Mission",
  description:
    "Our mission is to empower innovation by crafting cutting-edge software solutions. With a focus on integrity and innovation, we aim to be leaders in shaping the future of technology-driven business evolution. Our commitment to ethical practices, environmental responsibility, and social impact guides every aspect of our work. By creating software solutions that balance economic success with social and environmental stewardship, we aim to contribute to a more sustainable and inclusive future. Our goal is to be at the forefront of technology, driving positive change in industries and societies globally.",
  imageToShow: CompanyImage,
};

const AboutUsVision = {
  heading1: "Vision",
  description:
    "Our mission is to empower innovation by crafting cutting-edge software solutions. With a focus on integrity and innovation, we aim to be leaders in shaping the future of technology-driven business evolution. Our commitment to ethical practices, environmental responsibility, and social impact guides every aspect of our work. By creating software solutions that balance economic success with social and environmental stewardship, we aim to contribute to a more sustainable and inclusive future. Our goal is to be at the forefront of technology, driving positive change in industries and societies globally.",
  imageToShow: CompanyImage,
};

const HomePageNotableProjects = {
  description: `"Code with passion, innovate with purpose, and your applications will inspire the world."`,
  imageToShow: GroupImage,
  headerText: "Notable Projects",
};

const servicesWeOfferData = [
  {
    id: 1,
    iconName: <AppleIcon color="primary" fontSize="large" />,
    title: "iOS App Development",
    urlToNavigate: "/ios",
    description: `With our professional app developers, we build 
cutting-edge iPad, iPhone applications to meet your 
unique business requirements. Whether you need 
native or hybrid iPhone apps, with customized iOS 
applications for your startup or a robust enterprise 
business solution, we have the expertise to deliver 
outstanding results.`,
  },
  {
    id: 2,
    iconName: <AdbIcon color="primary" fontSize="large" />,
    urlToNavigate: "/android",
    title: "Android App Development",
    description: `Our Android application development team has helped start-ups and enterprises like yours build their brand across all scales, sizes and ecosystems.We provide a dedicated offshore Android app development team to deliver customized android app to grow your brand.`,
  },
  {
    id: 3,
    iconName: <CloudSyncIcon color="primary" fontSize="large" />,
    title: "Cloud App Development",
    urlToNavigate: "/cloud-application",
    description: `Our team combines their strong technical knowledge with holistic strategies to support you with migrating your applications & workloads to virtualized environments or refactoring them for more efficient utilization of cloud-native services. `,
  },
];

const androidAppDevelopMent = [
  {
    imgBgColor: "#FFE5E5",
    imageUrl: B2cProj,
    cardHeader: "B2C Online Grocery Ordering and Delivery Software",
    platform: "Android Application",
    frontEnd: "ReactNative",
    backEnd: "Node.js",
  },
  {
    imgBgColor: "#E5EDFF",
    imageUrl: ParcelDelProj,
    cardHeader: "Parcel Delivery Management Software",
    platform: "Android Application",
    frontEnd: "ReactNative",
    backEnd: "Node.js",
  },
  {
    imgBgColor: "#F5E0FF",
    imageUrl: PizzaProj,
    cardHeader: "On Demand Multi-Vendor Pizza Ordering and Delivery Software",
    platform: "Android Application",
    frontEnd: "ReactNative",
    backEnd: "Node.js",
  },
];

const notableProjects = [
  {
    imgBgColor: "#FFC785",
    imageUrl: WasteManProj,
    cardHeader: "Waste Product Management System ",
    platform: "Mobile Application",
    frontEnd: "ReactNative",
    backEnd: "Node.js",
  },
  {
    imgBgColor: "#9EFF7B5E",
    imageUrl: B2cProj,
    cardHeader: "Documentation Management System",
    platform: "Web Application",
    frontEnd: "React",
    backEnd: "Node.js",
  },
  {
    imgBgColor: "#F5E0FF",
    imageUrl: PizzaProj,
    cardHeader: "Multi Vendor Food Delivery System",
    platform: "Web Application",
    frontEnd: "React",
    backEnd: "Node.js",
  },
];

const ExperienceCardData = [
  {
    id: 1,
    count: "200+",
    text: "Technical professionals",
  },
  {
    id: 2,
    count: "12+",
    text: "Accomplished Projects",
  },
  {
    id: 3,
    count: "7+",
    text: "Clients",
  },
];

const ItStaffingWhyChooseUsData = [
  {
    heading: "Extensive Talent Network",
    description:
      "With a vast network of IT professionals, we have access to top talent across various domains, including software development, data science, cloud computing, and more.",
  },
  {
    heading: "Customized Solutions",
    description:
      "We understand that every company has unique needs. Our staffing solutions are tailored to meet your specific requirements, ensuring you get the right fit for your team.",
  },
  {
    heading: "Rigorous Screening Process",
    description:
      "Our thorough screening process includes technical assessments, background checks, and interviews to ensure we present only the best candidates for your consideration.",
  },
  {
    heading: "Industry Expertise",
    description:
      "With years of experience in the IT staffing industry, we have deep insights into market trends, salary benchmarks, and the skills in demand, enabling us to provide valuable guidance and support.",
  },
];
const OtherServicesLabelData = ["Social Media", "Government"];
const OtherServicesData = [...Object.keys(otherServicesCardsImages)].map(
  (item, index) => {
    return {
      imageToShowUrl: otherServicesCardsImages[item],
      label: OtherServicesLabelData.sort()[index],
    };
  }
);

const IOSProcessContent = [
  {
    step: step1,
    text: "Idea Generation",
    image: idea,
  },
  {
    step: step2,
    text: "Conceptualization and Planning",
    image: idea2,
  },
  {
    step: step3,
    text: "Design and Wireframe",
    image: idea3,
  },
  {
    step: step4,
    text: "Development",
    image: idea4,
  },
  {
    step: step5,
    text: "Testing and Quality Assurance",
    image: idea5,
  },
  {
    step: step6,
    text: "Deployment",
  },
];

const OurPreferences = [
  "We build Android apps using APIs in the Android framework and other libraries.The Android system implements the principle of least privilege. That is, each app, by default, has access only to the components that it requires to do its work and no more. This creates a very secure environment in which an app can't access parts of the system it is not given permission for.",
  "At runtime, Android detects the current device configuration and loads the appropriate resources for your app.",
  "App architecture design is an important consideration for ensuring that your apps are robust, testable, and maintainable.  We design app architect in protective way.",
  "Very first steps a budding developer can take on this path, to useful libraries and plug-ins for the more experienced users.",
  "The sections are ",
];

const Sections = [
  "Editors and IDEs",
  "Language Resources",
  "Libraries",
  "Plugins",
];

const Industries =
  "Creating an Android application for industries involves understanding the specific needs and requirements of the industry you are targeting.For  numerous industries that can benefit from mobile applications to streamline processes, enhance efficiency, and improve overall operations.";

const Benefits1 = [
  "Healthcare",
  "Retail",
  "Manufacturing",
  "Construction",
  "Logistics and Transportation",
  "Finance",
  "Education",
  "Hospitality",
  "Real Estate",
];

const Benefits2 = [
  "Energy",
  "Agriculture",
  "Entertainment",
  "Automotive",
  "Telecommunications",
  "Pharmaceuticals",
  "Government",
  "Sports and Fitness",
  "Environmental Services",
];

const Cloud = [
  {
    title: "Public Cloud",
    image: Cloud1,
    text: "Our services and resources are provided over the internet by a third-party cloud service provider. These services are made available to the general public, and customers can access and utilize computing resources.",
  },
  {
    title: "Private Cloud",
    image: Cloud2,
    text: "Our computing resources such as servers, storage, and networks are provisioned for the exclusive use of our organization. A private cloud infrastructure can be located on-premises or hosted by a third-party service provider.",
  },
  {
    title: "Hybrid Cloud",
    image: Cloud3,
    text: "Our applications can move seamlessly between on-premises data centers and public or private cloud environments. This flexibility provides businesses with greater choices and more deployment options to meet their specific requirements.",
  },
];

const WebPage = [
  // { image: WebImage },
  {
    title: "UI design",
    text: "Creating an effective UI (User Interface) design for web application development involves a combination of aesthetics, functionality, and usability. Regularly reassess the user experience, gather user feedback, and iterate on the design based on evolving requirements and industry trends. We Conduct user research to understand your target audience, their needs, and their behaviors and identify the primary goals and tasks users will accomplish through the web application",
    image: UIImage,
  },
  {
    title: "UX design",
    text: "Creating a positive User Experience (UX) for web application development involves understanding users' needs and behaviors to design interfaces that are intuitive, efficient, and enjoyable. Continuously seek input from users, gather analytics, and refine the design to enhance the overall user experience of the web application. We develop user personas to represent different user types based on demographics, goals, and behaviors.",
    image: UXImage,
  },
];

const OtherServicesHeadingDescriptionCardData = [
  {
    id: 0,
    imageName: "DataCleaning",
    heading: "Data Cleaning",
    description:
      "Clean and preprocess the data to handle missing values, outliers, and inconsistencies. This step ensures that the data is in a suitable format for analysis. They involve preparing raw data for analysis by addressing issues such as missing values, outliers, inconsistencies, and ensuring that the data is in a suitable format.",
  },
  {
    id: 1,
    imageName: "DataCollection",
    heading: "Data Collection",
    description: `Gather relevant data from various sources. This could include structured data from databases or spreadsheets, as well as unstructured data from sources like text or images. The quality and relevance of the collected data significantly impact the insights and conclusions derived from the analysis.`,
  },
  {
    id: 2,
    imageName: "ExploratoryDataAnalysis",
    heading: "Exploratory Data Analysis",
    description: `Explore the data using summary statistics, visualizations, and other exploratory techniques to understand its characteristics and patterns. EDA helps uncover patterns, relationships, anomalies, and insights that can inform subsequent analysis and decision-making.`,
  },
  {
    id: 3,
    imageName: "DecisionMaking",
    heading: "Decision Making",
    description: `Use the insights gained from the analysis to inform decision-making processes. Effective decision-making involves a combination of quantitative insights, qualitative considerations, and a deep understanding of the business or problem at hand.`,
  },
  {
    id: 4,
    imageName: "DocumentationAndReporting",
    heading: "Documentation and Reporting",
    description: `Document the entire analysis process, including methodologies, assumptions, and limitations. Present the results in a clear and understandable manner, often through reports or presentations.`,
  },
  {
    id: 5,
    imageName: "DataVisualization",
    heading: "Data Visualization",
    description: `Creating visual representations of the data, such as charts, graphs, and dashboards, to communicate insights effectively. Effective data visualization goes beyond simply creating charts and graphs; it aims to convey complex information in a clear and meaningful way.`,
  },
  {
    id: 6,
    imageName: "InterpretationOfResults",
    heading: "Interpretation of Results",
    description: `Interpret the findings from the analysis in the context of the original problem or question. It involves making sense of the findings and drawing meaningful conclusions.`,
  },
  {
    id: 7,
    imageName: "IterativeProcess",
    heading: `Iterative process`,
    description: `Data analysis is often an iterative process. As new data becomes available or as the problem evolves, the analysis may need to be revisited and updated.`,
  },
  {
    id: 8,
    imageName: "StatisticalAnalysis",
    heading: "Statistical Analysis",
    description: `Applying statistical methods to test hypotheses, identify patterns, and make inferences about the data. These methods help in summarizing, interpreting, and drawing inferences from data.`,
  },
];
const OtherServicesHeadingImageDescriptionCardData = [
  ...Object.keys(otherServicesDescriptiveCardImages),
].map((item, index) => {
  return {
    id: index,
    imageToShowUrl: otherServicesDescriptiveCardImages[item],
    heading: OtherServicesHeadingDescriptionCardData.filter(
      (cardImage) => cardImage.imageName === item
    )[0].heading,
    description: OtherServicesHeadingDescriptionCardData.filter(
      (cardImage) => cardImage.imageName === item
    )[0].description,
  };
});

export {
  BasePath,
  BannerTextConstant,
  ServiceMenuItems,
  HomePageWelcomeMessage,
  servicesWeOfferData,
  HomePageNotableProjects,
  IosPageWelcomeMessage,
  cloudPageWelcomeMessage,
  AndroidPageWelcomeMessage,
  WebPageWelcomeMessage,
  MaintenancePageWelcomeMessage,
  androidAppDevelopMent,
  OthersPageWelcomeMessage,
  AboutUsMission,
  AboutUsVision,
  notableProjects,
  ExperienceCardData,
  ItStaffingPageWelcomeMessage,
  ItStaffingWhyChooseUsData,
  OtherServicesData,
  OtherServicesHeadingImageDescriptionCardData,
  IOSProcessContent,
  OurPreferences,
  Sections,
  Industries,
  Benefits1,
  Benefits2,
  Cloud,
  WebPage,
};
