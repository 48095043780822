import { Box, Grid, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { BannerTextContext } from "../Context/BannerTextContext";
import { BannerTextConstant } from "../Constant";
import { Breakpoints } from "../Theme/schemes/PureLightTheme";
const IconWithText = ({
  iconName,
  textValue,
  urlToNavigate,
  paddingNeeded = true,
  customTextFontVariant,
  textColor,
  centerAlignNeeded = true,
  handleItemClick
}: any) => {
  const theme = useTheme();
  const navigateTo = useNavigate();
  const handleServiceItemClick = (urlToNavigate: any) => {
    navigateTo(urlToNavigate);
    handleItemClick();
  };

  const { ChangeBannerContent } = useContext(BannerTextContext);

  return (
    <>
      <Grid
        container
        direction="row"
        sx={{
          padding: paddingNeeded ? "0px 10px" : "0px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
            justifyContent: centerAlignNeeded ? "center" : "left",
            padding: paddingNeeded ? "0px 5px" : "0px",
          },
        }}
        onClick={() => {
          handleServiceItemClick(urlToNavigate);
          ChangeBannerContent(
            BannerTextConstant.filter(
              (item) => item.matchingRoute === urlToNavigate
            )[0]
          );
        }}>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            padding: paddingNeeded ? "10px" : "10px 0px",
            alignItems: "center",
            [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
              padding: paddingNeeded ? "5px" : "5px 0px",
            },
          }}>
          {iconName}
        </Grid>
        <Grid
          item
          xs={10}
          sx={{
            padding: paddingNeeded ? "10px" : "10px 0px",
            color: textColor ? textColor : theme.palette.greyColor.light,
            display: "flex",
            alignItems: "center",
            [`@media screen and (max-width: ${Breakpoints.values.md}px)`]: {
              padding: paddingNeeded ? "5px" : "5px 0px",
            },
            //fontWeight: 700,
          }}>
          <Box
            sx={{
              paddingLeft: " 5px",
              display: "inline-block",
              position: "relative",
              "&:after": {
                content: '""',
                position: "absolute",
                width: "90%",
                transform: "scaleX(0)",
                height: "2px",
                bottom: -5,
                left: 10,
                backgroundColor: theme.palette.primary.main,
                transformOrigin: "bottom right",
                transition: "transform 0.25s ease-out",
              },
              "&:hover:after": {
                transform: "scaleX(1)",
                transformOrigin: "bottom left",
              },
            }}>
            <Typography
              variant={
                customTextFontVariant ? customTextFontVariant : "subMenuFont"
              }>
              {textValue}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default IconWithText;
