import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
import { styled, keyframes } from "@mui/system";

interface StyledCardProps {
  inView?: boolean;
}
// Animation keyframes
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledCard = styled(Card)<StyledCardProps>(({ theme, inView }) => ({
  //borderRadius: "8px",
  //boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  boxShadow: "0px 6px 4px 0px #00000040",
  transition: "transform 0.3s, box-shadow 0.3s",
  opacity: 0,
  transform: "translateY(20px)",
  animation: inView ? `${fadeInUp} 2s forwards` : "none",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0px 8px 6px 0px #00000080",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "90%",
    margin: "auto",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "380px",
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "16px 16px 0px 0px",
  backgroundColor: theme.palette.backgroundGrey.light,
  color: "white",
}));

const Icon = styled(Box)(({ theme }) => ({
  marginLeft: "auto",
  "& img": {
    width: "120px",
    height: "120px",
  },
}));

const Content = styled(CardContent)(({ theme }) => ({
  padding: "0px 16px 0px 20px",
  height: "240px",
  [theme.breakpoints.down("md")]: {
    height: "160px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "200px",
  },
  backgroundColor: theme.palette.backgroundGrey.light,
  "&:last-child": {
    paddingBottom: "16px",
  },
}));

const ImageHeaderBlueDescriptionCard = ({ dataToShow }) => {
  const [loading, setLoading] = useState(true);
  const [inView, setInView] = useState(false);
  const cardRef = useRef();
  const theme = useTheme();

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000); // Simulate loading for 2 seconds
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [cardRef]);

  return (
    <Box position="relative" width="100%" ref={cardRef}>
      <StyledCard
        style={{
          filter: loading ? "blur(2px)" : "none",
          pointerEvents: loading ? "none" : "auto",
        }}
        inView={inView}>
        <Header>
          <Grid
            sx={{
              padding: "10px",
              backgroundColor: theme.palette.primary.dark,
              width: "80%",
            }}>
            <Typography
              variant="menuFont"
              sx={{ color: theme.palette.whiteColor.main }}>
              {dataToShow.heading}
            </Typography>
          </Grid>
          <Icon>
            <img src={dataToShow.imageToShowUrl} alt={dataToShow.heading} />
          </Icon>
        </Header>
        <Content>
          <Typography variant="bodyContentFont" sx={{ fontWeight: 600 }}>
            {dataToShow.description}
          </Typography>
        </Content>
      </StyledCard>
    </Box>
  );
};

export default ImageHeaderBlueDescriptionCard;
