import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { ImageWithText } from "../../Components";
import {
  ItStaffingPageWelcomeMessage,
  ItStaffingWhyChooseUsData,
} from "../../Constant";
import HeadingDescriptionAccordian from "../../Components/HeadingDescriptionAccordian";

const ItStaffingSolutions = () => {
  return (
    <Grid container direction={"column"}>
      <ImageWithText
        isTextAfterImage={true}
        isButtonNeeded={false}
        mainHeader={ItStaffingPageWelcomeMessage.heading1}
        mainHeaderLine2={ItStaffingPageWelcomeMessage.heading2}
        description={ItStaffingPageWelcomeMessage.description}
        description2={ItStaffingPageWelcomeMessage.description2}
        description3={ItStaffingPageWelcomeMessage.description3}
        imageToShow={ItStaffingPageWelcomeMessage.imageToShow}
      />
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Paper
          elevation={5}
          sx={{
            backgroundColor: "rgba(123, 152, 255, 0.32)",
            padding: "2% 2% 2% 0%",
            borderRadius: "20px",
            width: "80%",
          }}>
          <Typography
            paragraph
            variant="headingFont"
            sx={{ textAlign: "center" }}>
            Why Choose Us?
          </Typography>
          <HeadingDescriptionAccordian items={ItStaffingWhyChooseUsData} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ItStaffingSolutions;
