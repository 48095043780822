import { FC, ReactNode, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import NavBar from "../NavBar";
import React from "react";
import {
  Box,
  Grid,
  keyframes,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Wallpaper } from "./../../Assets/index";
import { BannerTextContext } from "../../Context/BannerTextContext";
import useViewportWidth from "./../../Hooks/useViewportWidth";
import { Breakpoints } from "../../Theme/schemes/PureLightTheme";
import ContactPage from "../../Content/HomePage/ContactPage";

interface BaseLayoutProps {
  children?: ReactNode;
}

interface TextOnBannerOnlyTextLeftAlignedProps {
  positionValueTop: any;
  inView: boolean;
}
interface TextOnBannerHeadingImageCenterAlignProps {
  positionValueTop: any;
  inView: boolean;
}

interface TextOnBannerHeadingLeftImageRightAlignContainerProps {
  positionValueTop: any;
  inView: boolean;
}

// Animation keyframes
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const fadeInUpMobile = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const ImageBannnerContent = styled("div")(({ theme }) => ({
  top: 0,
  zIndex: 7,
  position: "relative",
  left: 0,
}));

const NavBarContainer = styled("div")(({ theme }) => ({
  top: 0,
  zIndex: 7,
  position: "fixed",
}));
const TextOnBannerOnlyTextLeftAligned = styled(
  "div"
)<TextOnBannerOnlyTextLeftAlignedProps>(
  ({ theme, positionValueTop, inView }) => ({
    //top: 400,
    zIndex: 7,
    position: "absolute",
    left: 0,
    color: theme.palette.whiteColor.main,
    top: positionValueTop,
    padding: "0% 10% 0% 10%",
    animation: inView ? `${fadeInUp} 1s forwards` : "none",
    [theme.breakpoints.down("md")]: {
      animation: inView ? `${fadeInUpMobile} 1s forwards` : "none",
    },
    //lineHeight: "35px",
  })
);

const TextOnBannerHeadingImageCenterAlign = styled(
  "div"
)<TextOnBannerHeadingImageCenterAlignProps>(
  ({ theme, positionValueTop, inView }) => ({
    //top: 400,
    width: "100%",
    display: "box",
    flexFlow: "column",

    alignItems: "center",
    zIndex: 7,
    position: "absolute",
    left: 0,
    color: theme.palette.whiteColor.main,
    top: positionValueTop,
    animation: inView ? `${fadeInUp} 1s forwards` : "none",
    [theme.breakpoints.down("md")]: {
      animation: inView ? `${fadeInUpMobile} 1s forwards` : "none",
    },
  })
);

const TextOnBannerHeadingLeftImageRightAlignContainer = styled(
  "div"
)<TextOnBannerHeadingLeftImageRightAlignContainerProps>(
  ({ theme, positionValueTop, inView }) => ({
    width: "100%",
    display: "flex",
    //flexFlow: "row",
    //justifyContent: "space-evenly",
    zIndex: 7,
    position: "absolute",
    left: 0,
    color: theme.palette.whiteColor.main,
    top: positionValueTop,
    animation: inView ? `${fadeInUp} 1s forwards` : "none",
    [theme.breakpoints.down("md")]: {
      animation: inView ? `${fadeInUpMobile} 1s forwards` : "none",
    },
  })
);
const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const { bannerDetails } = useContext(BannerTextContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const deviceWidth = useViewportWidth();
  const [inView, setInView] = useState(false);
  const cardRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [cardRef]);

  return (
    <>
      <Box sx={{ marginX: "auto", maxWidth: 1920 }} ref={cardRef}>
        <ImageBannnerContent>
          <img src={Wallpaper} alt="dashboard" />
          {bannerDetails?.displayModel === "OnlyTextLeftAligned" ? (
            <Typography variant="bannerText_OnlyTextLeftAligned">
              <TextOnBannerOnlyTextLeftAligned
                inView={inView}
                positionValueTop={((deviceWidth / 2) * 50) / 100}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: bannerDetails?.textValue[0],
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: bannerDetails?.textValue[1],
                  }}
                ></p>
              </TextOnBannerOnlyTextLeftAligned>
            </Typography>
          ) : null}

          {bannerDetails?.displayModel === "HeadingImageCenterAlign" ? (
            <Typography variant="bannerText_HeadingImageCenterAlign_Heading">
              <TextOnBannerHeadingImageCenterAlign
                inView={inView}
                positionValueTop={((deviceWidth / 2) * 32) / 100}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: bannerDetails?.headingText,
                  }}
                ></p>
                {bannerDetails?.imagesource}
                <Grid
                  sx={{
                    padding: "0px 10%",
                    [`@media screen and (max-width: ${Breakpoints.values.md}px)`]:
                      {
                        padding: "0px 5%",
                      },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: bannerDetails?.textValue,
                  }}
                ></Grid>
              </TextOnBannerHeadingImageCenterAlign>
            </Typography>
          ) : null}

          {bannerDetails?.displayModel === "HeadingLeftImageRightAlign" ? (
            <TextOnBannerHeadingLeftImageRightAlignContainer
              inView={inView}
              positionValueTop={((deviceWidth / 2) * 40) / 100}
            >
              <Grid
                sx={{
                  width: "60%",
                  alignItems: "center",
                  justifyContent: "left",
                  paddingLeft: "10%",
                  display: "box",
                  flexFlow: "column",
                  [`@media screen and (max-width: ${Breakpoints.values.md}px)`]:
                    {
                      width: "100%",
                      padding: "0 10%",
                    },
                }}
              >
                <Typography variant="bannerText_HeadingLeftImageRightAlign_Heading">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: bannerDetails?.headingText,
                    }}
                  ></p>
                </Typography>
                <Typography variant="bannerText_smallSizeDescription">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: bannerDetails?.textValue,
                    }}
                  ></p>
                </Typography>
              </Grid>
              <Grid
                sx={{
                  width: "40%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  [`@media screen and (max-width: ${Breakpoints.values.md}px)`]:
                    {
                      width: "0%",
                      visibility: "hidden",
                    },
                }}
              >
                {bannerDetails?.imagesource}
              </Grid>
            </TextOnBannerHeadingLeftImageRightAlignContainer>
          ) : null}

          {bannerDetails?.displayModel === "CenterWithSubText" ? (
            <>
              <TextOnBannerHeadingImageCenterAlign
                inView={inView}
                positionValueTop={
                  isMobile
                    ? ((deviceWidth / 2) * 50) / 100
                    : ((deviceWidth / 2) * 40) / 100
                }
              >
                <Typography variant="bannerText_HeadingImageCenterAlign_Heading">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: bannerDetails?.headingText,
                    }}
                  ></p>
                </Typography>
                <Typography variant="bannerText_smallSizeDescription">
                  <Grid
                    sx={{
                      padding: "0px 10%",
                      textAlign: "center",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: bannerDetails?.textValue,
                    }}
                  ></Grid>
                </Typography>
              </TextOnBannerHeadingImageCenterAlign>
            </>
          ) : null}

          <NavBarContainer>
            <NavBar />
          </NavBarContainer>
        </ImageBannnerContent>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: "3% 4%",
          marginX: "auto",
          maxWidth: 1920,
        }}
      >
        {children || <Outlet />}
      </Box>
      <Box sx={{ marginX: "auto", maxWidth: 1920 }}>
        <ContactPage />
        <Footer />
      </Box>
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node,
};

export default BaseLayout;
