import { Grid, Typography } from "@mui/material";
import React from "react";

const Accomplished = () => {
  return (
    <Grid container direction={"column"}>
      <Typography
        variant="headingFont"
        color="primary"
        sx={{
          textAlign: "center",
          color: "#156CDD",
        }}>
        Our Portfolio
      </Typography>
      <Typography
        variant="bodyContentFont"
        paragraph
        sx={{ textAlign: "center" }}>
        Each project is unique, so adapt these guidelines to fit the specific
        needs and characteristics of the project at hand. We communication and
        collaboration are key throughout the development process and regularly
        seek feedback, be open to adjustments, and aim for continuous
        improvement in both the development process and the final product. We
        invest time in making it visually appealing, informative, and reflective
        of your skills and expertise.
      </Typography>
    </Grid>
  );
};

export default Accomplished;
