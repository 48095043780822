import React from "react";
import { Box, styled, SxProps } from "@mui/material";

interface ContentContainerProps {
  bgColor?: string;
}
interface FullWidthBoxProps {
  children: any;
  SxProps?: SxProps;
  backgroundColorValue?: string;
}

const ContentContainer = styled(Box)<ContentContainerProps>(
  ({ theme, bgColor }) => ({
    backgroundColor: bgColor ? bgColor : theme.palette.whiteColor.main,
    margin: "0px -4%",
    zIndex: 2,
  })
);
const FullWidthBox = ({
  children,
  SxProps,
  backgroundColorValue,
}: FullWidthBoxProps) => {
  return (
    <ContentContainer bgColor={backgroundColorValue} sx={SxProps}>
      {children}
    </ContentContainer>
  );
};

export default FullWidthBox;
