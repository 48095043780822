// ExperienceSection.js
import React from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { GirlWithDog } from "../Assets";
import { ExperienceCardData } from "../Constant";

const ExperiencePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
 /*  [theme.breakpoints.down("sm")]: {
    borderRadiusTopLeft: "0px",
    borderRadiusBottomLeft: "0px",
  }, */
  backgroundImage:
    "linear-gradient(180deg, rgba(0, 208, 246, 0.2) 0%, rgba(0, 141, 255, 0.2) 70%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
}));

const InfoCardWithImage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Container>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={3}>
          <img
            src={GirlWithDog}
            alt="Woman with laptop"
            style={{ width: "180%", maxWidth: "250px" }}
          />
        </Grid>
        <Grid item xs={12} md={9} container spacing={2} alignItems="center">
          <ExperiencePaper elevation={3}>
            <Grid item xs={12} md={5}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box sx={{ paddingLeft: "10px" }}>
                  <Typography variant="headingFont" gutterBottom>
                    Our<br></br> Experience
                  </Typography>
                  <br></br>
                  <Typography variant="bodyContentFont" textAlign="center">
                    We consistently deliver perfect services because we
                    understand that our clients deserve nothing less.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing={2} justifyContent="space-around">
                {ExperienceCardData.map((item, idx) => (
                  <Grid item key={idx} xs={12} sm={4} textAlign="center">
                    <div
                      style={{
                        border: "8px solid #FFF",
                        borderRadius: "50%",
                        width: isMobile ? 70 : 130,
                        height: isMobile ? 70 : 130,
                        margin: "0 auto",
                      }}
                    >
                      <div
                        style={{
                          border: "4px solid #156CDD",
                          borderRadius: "50%",
                          width: isMobile ? 60 : 120,
                          height: isMobile ? 60 : 120,
                          backgroundColor: "#FFF",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="menuFont">{item.count}</Typography>
                      </div>
                    </div>
                    <Typography sx={{ paddingTop: 1 }}>{item.text}</Typography>
                  </Grid>
                ))}
                {/*               <Grid item xs={4}>
                <StatBox>
                  <Typography variant="h4">200+</Typography>
                  <Typography variant="body2">
                    Technical professionals
                  </Typography>
                </StatBox>
              </Grid>
              <Grid item xs={4}>
                <StatBox>
                  <Typography variant="h4">12+</Typography>
                  <Typography variant="body2">Accomplished Projects</Typography>
                </StatBox>
              </Grid>
              <Grid item xs={4}>
                <StatBox>
                  <Typography variant="h4">7+</Typography>
                  <Typography variant="body2">Clients</Typography>
                </StatBox>
              </Grid> */}
              </Grid>
            </Grid>
          </ExperiencePaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InfoCardWithImage;
