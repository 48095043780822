import { Grid, Typography } from "@mui/material";
import React from "react";
import { ImageComp, ImageWithText } from "../../Components";
import { WebPageWelcomeMessage } from "../../Constant";
import {
  TechImage4,
  tech14,
  NodeImage,
  TechImage2,
  tech17,
  TechImage10,
  tech19,
  TechImage8,
  tech20,
  tech22,
  tech23,
  TechImage5,
  TechImage6,
  TechImage7,
  TechImage9,
} from "../../Assets";
import WebDesign from "../../Components/WebDesign";
import FullWidthBox from "../../Components/FullWidthBox";

const WebDevelopmentPage = () => {
  return (
    <Grid container direction={"column"}>
      <ImageWithText
        isButtonNeeded={false}
        mainHeader={WebPageWelcomeMessage.heading1}
        mainHeaderLine2={WebPageWelcomeMessage.heading2}
        description={WebPageWelcomeMessage.description}
        description2={WebPageWelcomeMessage.description2}
        description3={WebPageWelcomeMessage.description3}
        imageToShow={WebPageWelcomeMessage.imageToShow}
      />
      <FullWidthBox>
        <Grid>
          <WebDesign />
        </Grid>
      </FullWidthBox>
      <Grid sx={{ backgroundColor: "#F0F0F0", padding: "2%" }}>
        <Typography
          paragraph
          variant="bodyContentFont"
          sx={{ textAlign: "center" }}
        >
          <span style={{ color: "blue", textDecoration: "underline" }}>
            Core Technologies
          </span>{" "}
          used in Android application development
        </Typography>
        <ImageComp
          ImageList={[
            TechImage4,
            tech14,
            NodeImage,
            TechImage2,
            tech17,
            TechImage10,
            tech19,
            TechImage8,
            tech20,
            tech22,
            tech23,
            TechImage5,
            TechImage6,
            TechImage7,
            TechImage9,
          ]}
        ></ImageComp>
      </Grid>
    </Grid>
  );
};

export default WebDevelopmentPage;
