import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { UHLogo, UHLogoBackground } from "../../Assets";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Pathname, useLocation, useNavigate } from "react-router";
import { BannerTextContext } from "../../Context/BannerTextContext";
import { BannerTextConstant } from "../../Constant/index";
import MenuItemWithPopover from "./MenuItemWithPopover";
import ShowMoreIconSvg from "../../Components/ShowMoreIconSvg";
import NavBarItemsMobile from "./NavBarItemsMobile";

interface LogoBGContainerStandardProps {
  imageWidth: string;
}
interface NavItemProps {
  active?: boolean;
}

interface LogoBGContainerProps {
  imageWidth: string;
}
const OuterContainer = styled("div")(({ theme }) => ({
  position: "relative",
}));
const NavBarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  //alignItems: "center",
  justifyContent: "space-between",
}));
const NavBarContainerStandard = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  backgroundColor: theme.palette.backgroundBlue.main,
}));
const LogoContainer = styled("div")(({ theme }) => ({
  zIndex: 10,
  width: "90%",
  position: "absolute",
  top: 0,
  left: 0,
}));
const LogoContainerStandard = styled("div")(({ theme }) => ({
  zIndex: 10,
  width: "130%",
  position: "absolute",
  top: -5,
  left: 0,
}));
const LogoBGContainer = styled("div")<LogoBGContainerProps>(
  ({ theme, imageWidth }) => ({
    zIndex: 9,
    width: imageWidth,
    position: "relative",
    top: 0,
    left: 0,
  })
);
const LogoBGContainerStandard = styled("div")<LogoBGContainerStandardProps>(
  ({ theme, imageWidth }) => ({
    zIndex: 9,
    width: imageWidth,
    position: "relative",
    top: 0,
    left: 0,
    transitionTimingFunction: "linear",
  })
);
const NavItemsContainer = styled("div")(({ theme }) => ({
  paddingRight: "3%",
  width: "70%",
  paddingTop: "3%",
}));
const NavItemsContainerStandard = styled("div")(({ theme }) => ({
  paddingRight: "30px",
  width: "70%",
  //paddingTop: "1%",
}));
const NavList = styled("ul")(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  color: theme.palette.whiteColor.main,
  listStyleType: "none",
  justifyContent: "space-around",
}));
const NavListStandard = styled("ul")(({ theme }) => ({
  display: "flex",
  cursor: "pointer",
  color: theme.palette.greyColor.light,
  listStyleType: "none",
  justifyContent: "space-around",
}));
const MoreIconContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
  cursor: "pointer",
}));
const NavItem = styled("li")(({ active }: NavItemProps) => ({
  transition: "background-color 0.3s ease, color 0.3s ease",
  "&:hover": {
    //backgroundColor: "#575757",
    //color:theme.palette.primary.main,
    background: "linear-gradient(90deg,#004AAD,#0097B2)",
    webkitBackgroundClip: "text",
    webkitTextFillColor: "transparent",
    backgroundClip: "text",
    color: "transparent",
  },

  background: active ? "linear-gradient(90deg,#004AAD,#0097B2)" : null,
  webkitBackgroundClip: active ? "text" : null,
  webkitTextFillColor: active ? "transparent" : null,
  backgroundClip: active ? "text" : null,
  color: active ? "transparent" : null,

  /*   .nav-item a:hover,
.nav-item a:focus,
.nav-item a.active {
  background-color: #575757;
  color: #ffffff; */
}));

const NavBarItems = () => {
  const navigateTo = useNavigate();
  const theme = useTheme();
  const handleClickNavItem = (url: Pathname) => {
    navigateTo(url);
  };

  const { ChangeBannerContent } = useContext(BannerTextContext);
  const { pathname } = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showDesktopMenu, setShowDesktopMenu] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [activeIndex, setActiveIndex] = useState(pathname);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    handleDrawerToggle();
  });
  const handleDrawerToggle = () => {
    if (isMobile) {
      setShowMobileMenu(true);
      setShowDesktopMenu(false);
    } else {
      setShowDesktopMenu(true);
      setShowMobileMenu(false);
    }
  };
  const NavItemsComponent = () => {
    return (
      <>
        <NavItem
          active={activeIndex === "/home"}
          onClick={() => {
            handleClickNavItem("/home");
            setActiveIndex("/home");
            ChangeBannerContent(
              BannerTextConstant.filter(
                (item) => item.matchingRoute === "/home"
              )[0]
            );
          }}>
          Home
        </NavItem>
        <NavItem active={activeIndex === "/services"}>
          <MenuItemWithPopover
            handleItemClick={() => setActiveIndex("/services")}
            textToShow="Services"
          />
        </NavItem>
        <NavItem
          active={activeIndex === "/clients"}
          onClick={() => {
            handleClickNavItem("/clients");
            setActiveIndex("/clients");
            ChangeBannerContent(
              BannerTextConstant.filter(
                (item) => item.matchingRoute === "/clients"
              )[0]
            );
          }}>
          Clients
        </NavItem>
        <NavItem
          active={activeIndex === "/accomplished"}
          onClick={() => {
            handleClickNavItem("/accomplished");
            setActiveIndex("/accomplished");
            ChangeBannerContent(
              BannerTextConstant.filter(
                (item) => item.matchingRoute === "/accomplished"
              )[0]
            );
          }}>
          Accomplished
        </NavItem>
        <NavItem
          active={activeIndex === "/about-us"}
          onClick={() => {
            handleClickNavItem("/about-us");
            setActiveIndex("/about-us");
            ChangeBannerContent(
              BannerTextConstant.filter(
                (item) => item.matchingRoute === "/about-us"
              )[0]
            );
          }}>
          About Us
        </NavItem>
        <NavItem
          active={activeIndex === "/contact-us"}
          onClick={() => {
            handleClickNavItem("/contact-us");
            setActiveIndex("/contact-us");
            ChangeBannerContent(
              BannerTextConstant.filter(
                (item) => item.matchingRoute === "/contact-us"
              )[0]
            );
          }}>
          Contact Us
        </NavItem>
      </>
    );
  };
  return (
    <>
      <OuterContainer>
        <NavBarContainer id="UHNavBar" className="UHNavShow">
          <LogoBGContainer imageWidth={isMobile ? "60%" : "25%"}>
            <img src={UHLogoBackground} alt="UHLogo Background" />
            <LogoContainer>
              <img src={UHLogo} alt="Unitedhands Logo" />
            </LogoContainer>
          </LogoBGContainer>
          <NavItemsContainer>
            {showDesktopMenu && (
              <Typography variant="menuFont">
                <NavList>
                  <NavItemsComponent />
                </NavList>
              </Typography>
            )}
            {showMobileMenu && (
              <MoreIconContainer>
                <ShowMoreIconSvg
                  handleIconClick={() => setOpenMobileMenu(!openMobileMenu)}
                  iconColor={theme.palette.whiteColor.main}
                />
              </MoreIconContainer>
            )}
          </NavItemsContainer>
        </NavBarContainer>
        <NavBarContainerStandard
          id="UHNavBarStandard"
          className="UHNavStandardHide">
          <LogoBGContainerStandard imageWidth={isMobile ? "50%" : "10%"}>
            <img src={UHLogoBackground} alt="UHLogo Background" />
            <LogoContainerStandard>
              <img src={UHLogo} alt="Unitedhands Logo" />
            </LogoContainerStandard>
          </LogoBGContainerStandard>
          <NavItemsContainerStandard>
            {showDesktopMenu && (
              <Typography variant="menuFont">
                <NavListStandard>
                  <NavItemsComponent />
                </NavListStandard>
              </Typography>
            )}
            {showMobileMenu && (
              <MoreIconContainer>
                <ShowMoreIconSvg
                  handleIconClick={() => setOpenMobileMenu(!openMobileMenu)}
                  iconColor={theme.palette.blackColor.main}
                />
              </MoreIconContainer>
            )}
          </NavItemsContainerStandard>
        </NavBarContainerStandard>
        <NavBarItemsMobile
          drawerOpen={openMobileMenu}
          handleDrawerOpen={() => setOpenMobileMenu(!openMobileMenu)}
        />
      </OuterContainer>
    </>
  );
};
export default NavBarItems;
