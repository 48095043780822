import { Grid, Typography } from "@mui/material";
import React from "react";
import { ImageWithText } from "../../Components";
import { AboutUsMission, AboutUsVision } from "../../Constant";
import InfoCardWithImage from "../../Components/InfoCardWithImage";

const AboutUs = () => {
  return (
    <Grid container direction={"column"}>
      <Typography
        variant="headingFont"
        color="primary"
        sx={{ textAlign: "center", color: "#156CDD" }}>
        Our Principles
      </Typography>
      <Typography
        variant="bodyContentFont"
        paragraph
        sx={{ textAlign: "center" }}>
        Our Company built on a foundation of innovation, customer-centricity,
        and transparency. By fostering a culture of continuous innovation, the
        company can stay at the forefront of technological advancements,
        ensuring its products and services remain relevant and cutting-edge.
        Prioritizing the needs and satisfaction of customers is paramount,
        requiring active engagement, feedback collection, and a commitment to
        addressing user concerns. Security and privacy considerations should be
        ingrained in the company's DNA, ensuring the protection of user data and
        compliance with regulations.Employee well-being and community engagement
        round out the principles, emphasizing the importance of a supportive
        workplace and the company's broader impact on the communities it serves.
        Our principles collectively form the bedrock of a company that not only
        thrives commercially but also upholds values that contribute positively
        to the industry and society at large.
      </Typography>
      <ImageWithText
        isButtonNeeded={false}
        heading1={AboutUsMission.heading1}
        description={AboutUsMission.description}
        imageToShow={AboutUsMission.imageToShow}></ImageWithText>
      <ImageWithText
        isTextAfterImage={true}
        isButtonNeeded={false}
        heading1={AboutUsVision.heading1}
        description={AboutUsVision.description}
        imageToShow={AboutUsVision.imageToShow}></ImageWithText>

      <InfoCardWithImage />
    </Grid>
  );
};

export default AboutUs;
