import React from "react";
import "./App.css";
import { useRoutes } from "react-router-dom";
import routes from "./router";
import ThemeProviderWrapper from "./Theme/ThemeProvider";
import { useTheme } from "@mui/material";
import { ScrollToTopAndBannerUpdate } from "./Components";
function App() {
  const content = useRoutes(routes);
  const myTheme = useTheme();

  return (
    <>
      <ThemeProviderWrapper theme={myTheme}>
        {content}
        <ScrollToTopAndBannerUpdate />
      </ThemeProviderWrapper>
    </>
  );
}

export default App;
