import { Grid, SxProps, Typography, styled, useTheme } from "@mui/material";
import React, { FC } from "react";

const ImageWrapper = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ".tech-img": {
    transition: "all 0.4s ease",
    "&:hover": {
      transform: "scale(0.8)",
      transition: "all 0.4s ease",
    },
  },
}));

const TechListComp: FC<{
  listHeader: string;
  sx?: SxProps;
  ImageList: string[];
}> = ({ listHeader, sx, ImageList }) => {
  const theme = useTheme();

  return (
    <Grid container direction="column" rowGap={2} sx={sx}>
      <Typography
        variant="headingFont"
        sx={{
          alignSelf: "center",
          textDecoration: "underline",
          color: theme.palette.blackColor.main,
        }}>
        {listHeader}
      </Typography>
      <Grid
        item
        xs
        container
        gap={5}
        sx={{ justifyContent: "center", pt: "2%" }}>
        {ImageList.length
          ? ImageList.map((itm) => {
              return (
                <ImageWrapper item xs={2}>
                  <img
                    src={itm}
                    alt="Tech images"
                    className="tech-img"
                    style={{
                      aspectRatio: 1.5,
                      height: "90px",
                      objectFit: "contain",
                      mixBlendMode: "multiply",
                    }}
                  />
                </ImageWrapper>
              );
            })
          : null}
      </Grid>
    </Grid>
  );
};

export default TechListComp;
