import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { UHLogoResized } from "../../Assets";

import { useTheme } from "@mui/material";

const ContactUs = () => {
  const theme = useTheme();
  return (
    <Container>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              backgroundColor: theme.palette.darkBackGroundBlue.light,
              color: "#fff",
              padding: "20px",
            }}>
            <Typography variant="menuFont">Contact info</Typography>
            <Box display="flex" alignItems="center" mt={2}>
              <LocationOnIcon />
              <Typography
                variant="bodyContentFont"
                style={{ marginLeft: "10px", textAlign: "left" }}>
                No. 90, Sri Velavan Complex, Vaiyapuri Nagar(West), Karur,
                TamilNadu, India - 639002.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={2}>
              <EmailIcon />
              <Typography
                variant="bodyContentFont"
                style={{ marginLeft: "10px", textAlign: "left" }}>
                admin@unitedhands.cc
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={2}>
              <PhoneIcon />
              <Typography
                variant="bodyContentFont"
                style={{ marginLeft: "10px", textAlign: "left" }}>
                +91-7624919806
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            style={{ padding: "0px 20px", width: "100%" }}>
            <Box
              mt={2}
              sx={{
                paddingX: "30%",
                [theme.breakpoints.down("md")]: {
                  padding: "0 10%",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}>
              <img src={UHLogoResized} alt="Map" />
            </Box>
            <Box mt={2} sx={{}}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15668.111663540467!2d78.0670718!3d10.9612638!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baa2f171b8b688d%3A0x37e9efd0a74d44cb!2sUnitedhands%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1721722438849!5m2!1sen!2sin"
                width="100%"
                /* height="450" */
                loading="lazy"
                title="Unitedhands Pvt Ltd"></iframe>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};
export default ContactUs;
