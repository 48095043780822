import { styled } from "@mui/material/styles";
import React from "react";
import NavBarItems from "./NavBarItems";
import { Box, useMediaQuery, useTheme } from "@mui/material";

interface CustomRootProps {
  needToShow?: boolean;
}
const Root = styled("div")<CustomRootProps>(({ theme, needToShow }) => ({
  cursor: "pointer",
  position: "sticky",
  top: 0,
  zIndex: 8,
  width: "100vw",
  marginX: "auto",
  maxWidth: 1920,
  visibility: needToShow ? "visible" : "hidden",
}));

const NavBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const scrollTopValueToCheck = isMobile ? 100 : 180;
  React.useEffect(() => {
    window.onscroll = function () {
      if (document.documentElement.scrollTop > scrollTopValueToCheck) {
        const navBar = document.getElementById("UHNavBar");
        const navBarStandard = document.getElementById("UHNavBarStandard");
        if (navBar) {
          navBarStandard.classList.add("UHNavStandardShow");
          navBarStandard.classList.remove("UHNavStandardHide");
          navBar.classList.add("UHNavHide");
          navBar.classList.remove("UHNavShow");
        }
      } else {
        const navBar = document.getElementById("UHNavBar");
        const navBarStandard = document.getElementById("UHNavBarStandard");
        if (navBar) {
          navBarStandard.classList.add("UHNavStandardHide");
          navBarStandard.classList.remove("UHNavStandardShow");
          navBar.classList.add("UHNavShow");
          navBar.classList.remove("UHNavHide");
        }
      }
    };
  }, []);

  return (
    <Box sx={{ marginX: "auto", maxWidth: 1920 }}>
      <Root>
        <NavBarItems />
      </Root>
    </Box>
  );
};
export default NavBar;
