import { styled } from "@mui/material/styles";
import React, { FC, ReactNode } from "react";
import IconWithText from "./IconWithText";
import { Typography, useTheme } from "@mui/material";

interface IcomImageWithDescriptionCardProps {
  children?: ReactNode;
}
interface IcomImageWithDescriptionCardProps {
  iconName?: JSX.Element;
  title?: String;
  urlToNavigate?: String;
  description?: String;
}

const OuterContainer = styled("div")(({ theme }) => ({
  display: "box",
  flexFlow: "column",

  backgroundColor: theme.palette.backgroundBlue.light,

  [theme.breakpoints.up("sm")]: {
    minWidth: "26%",
    maxWidth: "26%",
    padding: "2% 3%",
  },
  [theme.breakpoints.down("sm")]: {
    minWidth: "70%",
    maxWidth: "70%",
    padding: "3% 8% 8% 8%",
    margin: "2% 0%",
  },
  borderRadius: "20px",
  transition: "transform 0.1s",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const DescriptionContainer = styled("div")(({ theme }) => ({}));

const IcomImageWithDescriptionCard: FC<IcomImageWithDescriptionCardProps> = (
  props: IcomImageWithDescriptionCardProps
) => {
  const { iconName, title, urlToNavigate, description } = props;
  const theme = useTheme();
  return (
    <OuterContainer>
      <IconWithText
        iconName={iconName}
        textValue={title}
        urlToNavigate={urlToNavigate}
        paddingNeeded={false}
        customTextFontVariant={"cardHeading"}
        textColor={theme.palette.primary.dark}
      />
      <Typography variant="cardContent">
        <DescriptionContainer>{description}</DescriptionContainer>
      </Typography>
    </OuterContainer>
  );
};

export default IcomImageWithDescriptionCard;
