import React from "react";
import {
  Grid,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const StartImage = styled("img")(() => ({
}));

const EndImage = styled("img")(() => ({
}));

const Image = styled("img")(() => ({
  maxWidth: "120px",
}));

const IOSProcess = ({ items }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      {isMobile ? (
        <Grid>
          {items.map((item: any, index: any) => (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Image src={item.step} />
              <Typography
              variant="menuFont"
                sx={{
                  paddingLeft: isMobile ? "35px" : "0px",
                }}
              >
                {item.text}
              </Typography>
              {item.image ? (
                <Image
                  src={item.image}
                  sx={{ width: "200px", height: "200px", paddingLeft: "70px" }}
                />
              ): null}
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid>
          {items.map((item: any, index: any) => (
            <Container>
              {index % 2 !== 0 && (
                <StartImage
                  src={item.image}
                  sx={{ paddingLeft: item.image ? "80px" : "200px" }}
                />
              )}
              <Image src={item.step} />
              <Typography
                variant="menuFont"
                sx={{
                  textWrap: "nowrap",
                  padding: "0px 30px"
                }}
              >
                {item.text}
              </Typography>
              {index % 2 === 0 && <EndImage src={item.image} />}
            </Container>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default IOSProcess;
