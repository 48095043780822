import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { TechListComp } from "../../Components";
import {
  Auxesis,
  Bosch,
  Es,
  Jll,
  seyone,
  Target_24,
  Tdp,
  TechMahindra,
  Technoduce,
  Trigent,
} from "../../Assets";

const Clients = () => {
  useEffect(() => {});
  return (
    <Grid container direction={"column"}>
      <TechListComp
        listHeader="Trusted By"
        ImageList={[
          Trigent,
          Technoduce,
          Auxesis,
          TechMahindra,
          Target_24,
          Jll,
          seyone,
          Bosch,
          Es,
          Tdp,
        ]}
        sx={{
          paddingY: "5%",
        }}></TechListComp>
    </Grid>
  );
};
export default Clients;
