import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { Grid, styled, SxProps } from "@mui/material";
interface ImageWrapperProps {
  inView: boolean;
}
const ImageWrapper = styled(Grid)(({ inView }: ImageWrapperProps) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "white",
  padding: "1%",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  transform: inView ? "rotateY(360deg)" : null,
  transition: inView ? "transform 3s ease" : null,

  ".tech-img": {
    transition: "all 0.4s ease",
    "&:hover": {
      transform: "scale(0.8)",
      transition: "all 0.4s ease",
    },
  },
}));

const ImageComp: FC<{
  sx?: SxProps;
  style?: CSSProperties;
  ImageList: string[];
}> = ({ ImageList, sx, style }) => {
  const [loading, setLoading] = useState(true);
  const [inView, setInView] = useState(false);
  const cardRef = useRef();
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000); // Simulate loading for 2 seconds
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [cardRef]);

  return (
    <Grid
      item
      xs
      container
      gap={4}
      sx={{ justifyContent: "center", pt: "2%", ...sx }}
      ref={cardRef}
    >
      {ImageList.length
        ? ImageList.map((itm, index) => {
            return (
              <ImageWrapper
                item
                xs={2}
                tabIndex={0}
                key={index}
                inView={inView}
              >
                <img
                  src={itm}
                  alt="Tech images"
                  className="tech-img"
                  style={{
                    ...style,
                    aspectRatio: 1.5,
                    height: "70px",
                    objectFit: "contain",
                    mixBlendMode: "multiply",
                  }}
                />
              </ImageWrapper>
            );
          })
        : null}
    </Grid>
  );
};

export default ImageComp;
