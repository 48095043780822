import {
  ChatBubbleOutline,
  EmailOutlined,
  PersonOutline,
  PhoneOutlined,
} from "@mui/icons-material";
import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { ButtonComponent } from "../../Components";
import { ContactUs } from "../../Assets";

const ContactPage = () => {
  const theme = useTheme();
  return (
    <Grid container direction={"column"} justifyContent={"center"}>
      <Grid
        container
        item
        sx={{
          backgroundColor: theme.palette.darkBackGroundBlue.main,
          padding: "2% 10%",
          gap: 2,
        }}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="headingFont" sx={{ color: "#D9E1FF" }}>
            Let's get connected
          </Typography>
          <br></br>
          <Typography variant="bodyContentFont" sx={{ color: "#D9E1FF" }}>
            If you any plans or messages, feel free to discuss with us.
          </Typography>
        </Grid>
        <Grid container justifyContent={"space-between"} sx={{ gap: 2 }}>
          <Grid
            container
            item
            xs={10}
            md={3}
            sx={{
              borderRadius: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              height="180px"
              style={{ borderRadius: "10px" }}
              src={ContactUs}
              alt="ContactUs"
            />
          </Grid>
          <Grid container item xs={10} md={4} sx={{ rowGap: 3 }}>
            <TextField
              fullWidth
              size="small"
              placeholder="Enter Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutline />
                  </InputAdornment>
                ),
              }}
              sx={{
                boxShadow: "0px 4px 4px 0px #00000040 inset",
                backgroundColor: "#FFF",
                borderRadius: "10px",
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                  borderWidth: "1px",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    borderWidth: "1px",
                  },
                },
                "&:hover:not(.Mui-focused)": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                },
              }}
            />
            <TextField
              fullWidth
              size="small"
              placeholder="Phone Number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneOutlined />
                  </InputAdornment>
                ),
              }}
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 4px 4px 0px #00000040 inset",
                backgroundColor: "#FFF",
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                  borderWidth: "1px",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    borderWidth: "1px",
                  },
                },
                "&:hover:not(.Mui-focused)": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                },
              }}
            />
            <TextField
              fullWidth
              size="small"
              placeholder="Email ID"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlined />
                  </InputAdornment>
                ),
              }}
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 4px 4px 0px #00000040 inset",
                backgroundColor: "#FFF",
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                  borderWidth: "1px",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    borderWidth: "1px",
                  },
                },
                "&:hover:not(.Mui-focused)": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                },
              }}
            />
          </Grid>
          <Grid container item xs={10} md={4}>
            {/* <TextareaAutosize /> */}
            <TextField
              fullWidth
              size="small"
              multiline={true}
              placeholder="Message"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ChatBubbleOutline />
                  </InputAdornment>
                ),
              }}
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 4px 4px 0px #00000040 inset",
                backgroundColor: "#FFF",
                height: 100,
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                  borderWidth: "1px",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                    borderWidth: "1px",
                  },
                },
                "&:hover:not(.Mui-focused)": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                },
              }}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                marginTop: 30,
              }}>
              <ButtonComponent
                buttonText="Submit"
                backgroundImage="linear-gradient(180deg, #D9E1FF 20%, #D9E1FF 100%)"
                btnBorderRadius={2}
                padding="5px 1.5rem"
                buttonTextColor="#3D3C3C"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactPage;
