import React from 'react';
import { PureLightTheme } from './schemes/PureLightTheme';
import ThemeProvider  from '@mui/material/styles/ThemeProvider';

const ThemeProviderWrapper: any = (props : any) => {
  //const theme = PureLightTheme();
  return (
      <ThemeProvider theme={PureLightTheme}>{props.children}</ThemeProvider>
  );
};
export default ThemeProviderWrapper;
