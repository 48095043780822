import React, { FC } from "react";
import { Box, Typography, Grid, useTheme } from "@mui/material";
import { styled } from "@mui/system";

import ButtonComponent from "./ButtonComponent";

const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  //justifyContent: "center",
  // alignItems: "center",
}));

const HomePage: FC<{
  mainHeader?: string;
  mainHeaderLine2?: string;
  heading1?: string;
  heading2?: string;
  subHeading?: string;
  headerDescription?: string;
  description: string;
  description2?: string;
  description3?: string;
  imageToShow: string;
  isButtonNeeded?: boolean;
  buttonText?: string;
  isTextAfterImage?: boolean;
}> = ({
  mainHeader,
  mainHeaderLine2,
  heading1,
  heading2,
  subHeading,
  headerDescription,
  description,
  description2,
  description3,
  imageToShow,
  isButtonNeeded = true,
  buttonText,
  isTextAfterImage = false,
}) => {
  const theme = useTheme();
  return (
    <Grid container>
      {mainHeader ? (
        <Grid container justifyContent="center">
          <Typography
            variant="headingFont"
            sx={{
              color: theme.palette.blackColor.main,
              textAlign: "center",
            }}>
            {mainHeader} <br />
            <span>{mainHeaderLine2}</span>
          </Typography>
        </Grid>
      ) : null}
      {headerDescription ? (
        <Grid container justifyContent="center">
          <Typography
            sx={{ textAlign: "center" }}
            variant="bodyContentFont"
            paragraph>
            {headerDescription}
          </Typography>
        </Grid>
      ) : null}
      <Grid
        container
        // alignItems="center"
        justifyContent="space-between"
        sx={{
          flexDirection: isTextAfterImage ? "row-reverse" : "row",
          paddingY: "4%",
          paddingX: "2%",
        }}>
        <Grid item xs={12} md={6}>
          <ContentContainer>
            <Typography variant="headingFont" color="primary">
              {heading1}
            </Typography>
            <Typography variant="headingFont" color="primary">
              {heading2}
            </Typography>
            <Typography variant="subHeadingFont">{subHeading}</Typography>
            <Typography variant="bodyContentFont" paragraph>
              {description}
            </Typography>

            {description2 ? (
              <Typography variant="bodyContentFont" paragraph>
                {description2}
              </Typography>
            ) : null}

            {description3 ? (
              <Typography variant="bodyContentFont" paragraph>
                {description3}
              </Typography>
            ) : null}

            {isButtonNeeded ? (
              <Grid
                sx={{
                  //paddingTop: "1%",
                  alignItems: "center",
                  justifyContent: "left",
                  display: "flex",
                  [`@media screen and (max-width: ${theme.breakpoints.values.md}px)`]:
                    {
                      justifyContent: "center",
                    },
                }}>
                <ButtonComponent
                  buttonText={buttonText}
                  backgroundImage="linear-gradient(180deg, #5CDEE5 0%, #1A74BD 100%)"
                  btnBorderRadius={2}
                  padding=".6rem 1.5rem"
                />
              </Grid>
            ) : null}
          </ContentContainer>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          sx={{
            paddingX: "4%",
          }}>
          <img
            src={imageToShow}
            alt="Laptop and phone"
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomePage;
